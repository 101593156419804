import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PwToastNotificationService } from '@pw/shared/logger';

@Injectable()
export class PwHttpErrorInterceptor implements HttpInterceptor {
  private readonly notificationService = inject(PwToastNotificationService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap({
        error: (errorResponse) => {
          const isPathwaysApiError = Array.isArray(errorResponse.error.error) && errorResponse.error.message;

          if (isPathwaysApiError) {
            this.notificationService.apiError(errorResponse.message, errorResponse);
          } else {
            this.showErrorToast();
          }
        },
      }),
    );
  }

  private showErrorToast() {
    this.notificationService.error($localize`There was an error communicating with the server. Please try again later.`, $localize`API Error`);
  }
}
