<pw-notification *ngIf="formMessage" [message]="formMessage" (notificationMessageClosed)="emitNotificationMessageClosed()" key="change-email"></pw-notification>
<pw-input
  inputId="student-email"
  inputType="email"
  labelText="New email address"
  i18n-labelText
  [(inputValue)]="email"
  [errorText]="emailErrorText"
  [invalid]="emailErrorText !== '' || emailConfirmationErrorText !== ''"
  (fieldBlur)="formIsValid = validateEmail()"
  [maxlength]="maxlengthAllowedforQrcode"
></pw-input>
<pw-input
  *ngIf="requestConfirmation"
  inputId="student-email_confirmation"
  inputType="email"
  labelText="Confirm new email address"
  i18n-labelText
  [(inputValue)]="emailConfirmation"
  [errorText]="emailConfirmationErrorText"
  (fieldBlur)="formIsValid = validateEmailConfirm()"
></pw-input>
<div class="change-email-form__buttons">
  <pw-button label="Cancel" [size]="buttonSize.Medium" [secondary]="true" (buttonClicked)="cancel.emit()" i18n-label></pw-button>
  <pw-button [label]="confirmButtonText" [size]="buttonSize.Medium" [loading]="sending" [disabled]="!formIsValid" (buttonClicked)="submit()"></pw-button>
</div>
