import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RadioButtonModule } from 'primeng/radiobutton';

@Component({
  selector: 'pw-radio-buttons',
  standalone: true,
  imports: [CommonModule, FormsModule, RadioButtonModule],
  templateUrl: './radio-buttons.component.html',
  styleUrls: ['./radio-buttons.component.scss'],
})
export class RadioButtonsComponent<T> {
  @Input() radioButtonName = '';
  @Input() options: T[] = [];
  @Input() optionSelected: T | undefined;
  @Output() selectedOption = new EventEmitter<T>();
}
