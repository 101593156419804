<ng-container *ngIf="isMobile$ | async; else dialog">
  <p-breadcrumb [home]="breadcrumbHome"></p-breadcrumb>
  <pw-private-page-main pageTitle="Confirm your UK address" i18n-pageTitle>
    <pw-confirm-uk-address-content
      [(isFirstStep)]="isFirstStep"
      [firstStepOptions]="firstStepOptions"
      [secondStepOptions]="secondStepOptions"
      (cancelButtonClicked)="handleCancel()"
      (confirmButtonClicked)="handleConfirm($event)"
    ></pw-confirm-uk-address-content>
  </pw-private-page-main>
</ng-container>
<ng-template #dialog>
  <p-dialog [visible]="true" [modal]="true" [draggable]="false" [resizable]="false" [closable]="false">
    <ng-template pTemplate="header">
      <h6 i18n>Confirm your UK address</h6>
    </ng-template>
    <pw-confirm-uk-address-content
      [firstStepOptions]="firstStepOptions"
      [secondStepOptions]="secondStepOptions"
      (cancelButtonClicked)="handleCancel()"
      (confirmButtonClicked)="handleConfirm($event)"
    ></pw-confirm-uk-address-content>
  </p-dialog>
</ng-template>
