import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user.model';
import { ReportingAuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserResolver implements Resolve<User> {
  constructor(private authService: ReportingAuthService) {}

  resolve(): Observable<User> {
    const authResult = this.authService.getAuthenticationResult();
    if (authResult) {
      const user = this.authService.getUser();
      return of(user);
    } else {
      return this.authService.ssoSilent().pipe(map(() => this.authService.getUser()));
    }
  }
}
