/**
 * Student Notifications API
 * Student notification
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TaskItemApiDto } from './task-item.model';

export interface NotificationItemApiDto {
  id: string;
  name: NotificationItemApiDto.NameApiDtoEnum;
  status: NotificationItemApiDto.StatusApiDtoEnum;
  completionDate: string;
  category: NotificationItemApiDto.CategoryApiDtoEnum;
  displayOrder: string;
  type: NotificationItemApiDto.TypeApiDtoEnum;
  collegeCode: string;
  tasks: Array<TaskItemApiDto>;
  createdAt: string;
  modifiedAt: string;
}
export namespace NotificationItemApiDto {
  export type NameApiDtoEnum =
    | 'tuition_fee'
    | 'proof_of_accommodation'
    | 'enrolment_documents'
    | 'pre_arrival_tasks'
    | 'pre_arrival_info'
    | 'pre_arrival_info_pw_preview';
  export const NameApiDtoEnum = {
    tuition_fee: 'tuition_fee' as NameApiDtoEnum,
    proof_of_accommodation: 'proof_of_accommodation' as NameApiDtoEnum,
    enrolment_documents: 'enrolment_documents' as NameApiDtoEnum,
    pre_arrival_tasks: 'pre_arrival_tasks' as NameApiDtoEnum,
    pre_arrival_info: 'pre_arrival_info' as NameApiDtoEnum,
    pre_arrival_info_pw_preview: 'pre_arrival_info_pw_preview' as NameApiDtoEnum,
  };
  export type StatusApiDtoEnum = 'new' | 'completed';
  export const StatusApiDtoEnum = {
    new: 'new' as StatusApiDtoEnum,
    completed: 'completed' as StatusApiDtoEnum,
  };
  export type CategoryApiDtoEnum = 'pre_arrival';
  export const CategoryApiDtoEnum = {
    pre_arrival: 'pre_arrival' as CategoryApiDtoEnum,
  };
  export type TypeApiDtoEnum = 'task' | 'reminder' | 'alert' | 'info';
  export const TypeApiDtoEnum = {
    task: 'task' as TypeApiDtoEnum,
    reminder: 'reminder' as TypeApiDtoEnum,
    alert: 'alert' as TypeApiDtoEnum,
    info: 'info' as TypeApiDtoEnum,
  };
}
