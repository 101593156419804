import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserverService } from '@klg/shared/utils-dom';
import { AttendanceStat } from '@pw/profile-data-access';
import { CarouselModule } from 'primeng/carousel';
import { DividerModule } from 'primeng/divider';
import { combineLatest, Observable, Subject, takeUntil, tap } from 'rxjs';
import { NotificationPanelService } from '../../../services';
import { AttendancePercentageComponent } from '../../atoms';
import { AttendanceCarouselComponent } from '../attendance-carousel/attendance-carousel.component';
import { CardShellComponent } from '../card-shell/card-shell.component';

@Component({
  selector: 'pw-attendance-card',
  standalone: true,
  imports: [CommonModule, AttendancePercentageComponent, DividerModule, CarouselModule, AttendanceCarouselComponent, CardShellComponent],
  templateUrl: './attendance-card.component.html',
  styleUrls: ['./attendance-card.component.scss'],
})
export class AttendanceCardComponent implements OnInit, OnDestroy {
  @Input() attendanceStats: AttendanceStat[] = [];

  isMobile$: Observable<boolean>;
  isTablet$: Observable<boolean>;
  isPanelOpen$: Observable<boolean>;
  showCarousel = false;

  private readonly breakpointObserverService = inject(BreakpointObserverService);
  private readonly notificationPanelService = inject(NotificationPanelService);
  private readonly destroy$ = new Subject<void>();

  constructor() {
    this.isMobile$ = this.breakpointObserverService.isMobile$;
    this.isTablet$ = this.breakpointObserverService.isTablet$;
    this.isPanelOpen$ = this.notificationPanelService.isPanelOpen$;
  }

  ngOnInit(): void {
    combineLatest([this.isMobile$, this.isTablet$, this.isPanelOpen$])
      .pipe(
        tap(([isMobile, isTablet, isPanelOpen]) => (this.showCarousel = isMobile || (isTablet && isPanelOpen))),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
