<p-button
  #pButton
  [label]="label"
  [styleClass]="styleClass"
  [loading]="loading"
  [disabled]="disabled"
  [attr.data-gtm-id]="gtmId"
  (click)="disabled || onClick()"
>
  <img alt="" *ngIf="iconPath" [ngSrc]="iconPath" [width]="16" [height]="16" />
</p-button>
