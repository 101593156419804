import { inject, Injectable } from '@angular/core';
import { StudentNotificationsApiService } from '@pw/api/notification';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { PatchNotificationRequest, StudentNotification, StudentNotificationNameEnum, TaskItemName } from '../models';

@Injectable({
  providedIn: 'root',
})
export class StudentNotificationService {
  studentNotifications$: Observable<StudentNotification[]>;

  private studentNotifications = new BehaviorSubject<StudentNotification[]>([]);

  private readonly notificationService = inject(StudentNotificationsApiService);

  constructor() {
    this.studentNotifications$ = this.studentNotifications.asObservable();
  }

  getStudentNotifications(bearerToken: string, masterProfileId: string): Observable<StudentNotification[]> {
    return this.notificationService.listNotifications(bearerToken, masterProfileId).pipe(tap((response) => this.studentNotifications.next(response)));
  }

  updateNotification(bearerToken: string, masterProfileId: string, notificationId: string, payload: PatchNotificationRequest) {
    return this.notificationService.updateNotification(bearerToken, masterProfileId, notificationId, payload);
  }

  getPreArrivalTasks(): StudentNotification {
    return this.studentNotifications.value.find((notification) => notification.name === StudentNotificationNameEnum.pre_arrival_tasks);
  }

  findPreArrivalTask(taskName: TaskItemName) {
    return this.getPreArrivalTasks()?.tasks?.find((task) => task.name === taskName);
  }
}
