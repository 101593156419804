import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { StudentNotification, StudentNotificationNameEnum } from '@pw/notification-data-access';
import { PreArrivalInfoNotificationComponent } from './pre-arrival-info/pre-arrival-info-notification.component';
import { PwPreviewNotificationComponent } from './pw-preview/pw-preview.component';

@Component({
  selector: 'pw-notification-info-section',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, PreArrivalInfoNotificationComponent, PwPreviewNotificationComponent],
  templateUrl: './notification-info-section.component.html',
  styleUrls: ['./notification-info-section.component.scss'],
})
export class NotificationInfoSectionComponent implements OnChanges {
  @Input() infoNotifications: StudentNotification[] | undefined;

  eyeImg = '/assets/icons/eye.svg';
  preArrivalInfoNotificationData: StudentNotification | undefined;
  pwPreviewInfoNotificationData: StudentNotification | undefined;

  ngOnChanges({ infoNotifications }: SimpleChanges): void {
    const currentValue: StudentNotification[] = infoNotifications?.currentValue;

    if (currentValue) {
      this.preArrivalInfoNotificationData = currentValue.find((infoNotification) => infoNotification.name === StudentNotificationNameEnum.pre_arrival_info);
      this.pwPreviewInfoNotificationData = currentValue.find(
        (infoNotification) => infoNotification.name === StudentNotificationNameEnum.pre_arrival_info_pw_preview,
      );
    }
  }
}
