import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ComponentInputCompatibilityError } from '@klg/shared/utils';
import { Message } from 'primeng/api';
import { Severity } from './types';

@Component({
  selector: 'pw-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnChanges {
  @Input() severity: Severity = 'info';
  @Input() summary = '';
  @Input() detail = '';
  @Input() displayIcon = true;
  @Input() icon = '';
  @Input() iconStyle = null;

  @Input() set closable(value: string | boolean) {
    this._closable = coerceBooleanProperty(value);
  }
  get closable() {
    return this._closable;
  }
  private _closable = true;

  @Input() set message(message: Message) {
    const { severity, summary, detail, closable, icon } = message || {};
    this.severity = severity as Severity;
    this.summary = summary;
    this.detail = detail;
    this._closable = typeof closable === 'undefined' ? true : closable;
    this.icon = icon;
  }

  @Output() notificationMessageClosed = new EventEmitter<void>();

  ngOnChanges({ message, severity, summary, detail }: SimpleChanges): void {
    if (message && [severity, summary, detail].some(Boolean)) {
      throw new ComponentInputCompatibilityError('message', 'severity', 'summary', 'detail');
    }
  }

  closeMessage() {
    this.notificationMessageClosed.emit();
  }
}
