import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BreakpointObserverService } from '@klg/shared/utils-dom';
import { colleges } from '@pw/profile-data-access';
import { combineLatest, Observable, Subject, takeUntil, tap } from 'rxjs';
import { NotificationPanelService } from '../../services';

@Component({
  selector: 'pw-private-page-main',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './private-page-main.component.html',
  styleUrls: ['./private-page-main.component.scss'],
})
export class PrivatePageMainComponent implements OnInit, OnChanges, OnDestroy {
  @Input() collegeCode = '';
  @Input() pageTitle = '';
  @Input() pageSubtitle = '';

  collegeName = '';
  isTablet$: Observable<boolean>;
  isPanelOpen$: Observable<boolean>;
  isFlexColumn = false;

  private readonly breakpointObserverService = inject(BreakpointObserverService);
  private readonly notificationPanelService = inject(NotificationPanelService);
  private readonly destroy$ = new Subject<void>();

  constructor() {
    this.isTablet$ = this.breakpointObserverService.isTablet$;
    this.isPanelOpen$ = this.notificationPanelService.isPanelOpen$;
  }

  ngOnInit(): void {
    combineLatest([this.isTablet$, this.isPanelOpen$])
      .pipe(
        tap(([isTablet, isPanelOpen]) => (this.isFlexColumn = isTablet && isPanelOpen)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnChanges({ collegeCode }: SimpleChanges): void {
    if (collegeCode?.currentValue) {
      this.collegeName = colleges.find((college) => college.code === this.collegeCode)?.name;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
