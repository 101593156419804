<ng-container *ngIf="showCarousel">
  <pw-card-shell>
    <ng-template #header>
      <pw-attendance-carousel [attendanceStats]="attendanceStats" (pageChanged)="tabChanged.emit($event)"></pw-attendance-carousel>
    </ng-template>
    <ng-container *ngTemplateOutlet="content" />
  </pw-card-shell>
</ng-container>

<ng-container *ngIf="showCardShell">
  <pw-card-shell>
    <ng-container *ngTemplateOutlet="tabView" />
  </pw-card-shell>
</ng-container>

<ng-container *ngIf="!showCarousel && !showCardShell">
  <ng-container *ngTemplateOutlet="tabView" />
</ng-container>

<ng-template #tabView>
  <p-tabView [activeIndex]="activeTabIndex$ | async" (onChange)="onTabChange($event)">
    <ng-container *ngFor="let stat of attendanceStats">
      <p-tabPanel>
        <ng-template pTemplate="header">
          <pw-attendance-percentage [attendanceStat]="stat" [showFilter]="stat.frequency !== attendanceType.overall"></pw-attendance-percentage>
        </ng-template>
      </p-tabPanel>
    </ng-container>
    <ng-container *ngTemplateOutlet="content" />
  </p-tabView>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
