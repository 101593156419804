<h2 class="attendance-absences__heading" i18n>Attendance Overview</h2>
<span *ngIf="isAttendanceDataAvailable" class="attendance-absences__updated" i18n>Last updated on {{ yesterday | date : 'd MMM YYYY' }}</span>
<div *ngIf="!isAttendanceDataAvailable" class="attendance-overview__no-data" i18n>No data available yet</div>
<div class="attendance-absences__info">
  <img alt="Info icon" [ngSrc]="infoImg" width="16" height="16" />
  <span i18n>Changes may not show here immediately. Please come back later if you are waiting for an update</span>
</div>
<pw-button
  buttonId="attendance-absences-submit-absence-form-button"
  class="attendance-absences__button"
  label="Submit Absence Form"
  [secondary]="true"
  icon="arrow-up-right"
  [gtmId]="gtmEventNames.DETAIL_ATTENDANCE_SUBMIT_ABSENCE_FORM_BUTTON"
  [href]="absenceFormUrl"
  i18n-label
></pw-button>
<ng-container *ngIf="isAttendanceDataAvailable">
  <pw-attendance-tabs [class.attendance-absences__tabs--short-card]="shortCard" [attendanceStats]="attendanceStats" (tabChanged)="onTabChanged($event)">
    <div class="attendance-absences__table" [class.attendance-absences__table--panel-open]="isPanelOpen$ | async">
      <pw-attendance-table [absences]="absences"></pw-attendance-table>
    </div>
  </pw-attendance-tabs>
</ng-container>
<span class="attendance-absences__help" i18n
  >If you have any questions, please contact College staff at
  <a class="attendance-absences__help-link" href="mailto:{{ serviceEmail }}" [attr.data-gtm-id]="gtmEventNames.DETAIL_ATTENDANCE_COLLEGE_EMAIL_LINK">{{
    serviceEmail
  }}</a></span
>
<pw-button
  buttonId="attendance-absences-policy-button"
  class="attendance-absences__button"
  label="Attendance Policy"
  [secondary]="true"
  icon="document"
  href="https://pathways.kaplaninternational.com/pluginfile.php/432111/mod_resource/content/8/KI Pathways Engagement and Attendance Policy.pdf"
  [gtmId]="gtmEventNames.DETAIL_ATTENDANCE_POLICY_BUTTON"
  i18n-label
></pw-button>
