import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { parsePwApiError } from '../functions/parse-pw-api-error.function';
import { ToastNotificationService } from '@klg/shared/logger';

@Injectable({ providedIn: 'root' })
export class PwToastNotificationService extends ToastNotificationService {
  override apiError(_message: string, responseError: HttpErrorResponse): void {
    const parsedMessage = parsePwApiError(responseError);
    this.error(parsedMessage, responseError.error.message);
  }
}
