<p-messages [severity]="severity">
  <ng-template pTemplate>
    <span
      class="p-message-icon"
      [ngStyle]="icon && iconStyle ? iconStyle : null"
      [ngClass]="icon ? icon : severity ? 'pw-icon-' + severity : ''"
      *ngIf="displayIcon"
    ></span>
    <span *ngIf="summary" class="p-message-summary">{{ summary }}</span>
    <div class="p-message-detail">
      {{ detail }}
      <ng-content></ng-content>
    </div>
    <button type="button" pripple class="p-ripple p-element p-message-close p-link" (click)="closeMessage()" *ngIf="closable">
      <i class="p-message-close-icon pi pi-times"></i>
    </button>
  </ng-template>
</p-messages>
