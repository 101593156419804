import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { Severity, Size } from '@pw/shared/types';
import { TagModule } from 'primeng/tag';

@Component({
  selector: 'pw-badge',
  standalone: true,
  imports: [CommonModule, TagModule],
  templateUrl: './pw-badge.component.html',
  styleUrls: ['./pw-badge.component.scss'],
})
export class PwBadgeComponent implements OnChanges {
  @Input() label = 'success';
  @Input() severity: Severity = Severity.Success;
  @Input() size: Size = Size.Small;
  @Input() style: { [klass: string]: string } | undefined;

  styleClass = '';

  ngOnChanges(): void {
    const sizeClass = `pw-badge--${this.size}`;
    const severityClass = `p-tag-${this.severity}`;
    this.styleClass = `${sizeClass} ${severityClass}`;
  }
}
