import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { PathwaysAuthService } from '@pw/account/auth';
import {
  PatchNotificationRequest,
  PatchNotificationRequestActionEnum,
  StudentNotification,
  StudentNotificationNameEnum,
  StudentNotificationService,
  TaskItemStatusEnum,
} from '@pw/notification-data-access';
import { environment } from '@pw/shared/environment';
import { Severity } from '@pw/shared/types';
import { catchError, of, switchMap, take } from 'rxjs';
import { NotificationPanelService } from '../../../services';
import { NotificationToastComponent } from '../../molecules';
import { PreArrivalTasksNotificationComponent } from './pre-arrival-tasks/pre-arrival-tasks-notification.component';
import { NotificationInfoSectionComponent } from './notification-info-section/notification-info-section.component';

@Component({
  selector: 'pw-notification-panel',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, PreArrivalTasksNotificationComponent, NotificationToastComponent, NotificationInfoSectionComponent],
  templateUrl: './notification-panel.component.html',
  styleUrls: ['./notification-panel.component.scss'],
})
export class NotificationPanelComponent {
  @Input() studentNotifications: StudentNotification[] = [];
  @Input() infoNotifications: StudentNotification[] = [];

  closeIcon = 'assets/icons/close.svg';
  celebrationImg = 'assets/illustrations/celebration.svg';
  notificationNameEnum = StudentNotificationNameEnum;
  taskItemStatusEnum = TaskItemStatusEnum;
  toastSeverity = Severity;

  private readonly notificationPanelService = inject(NotificationPanelService);
  private readonly notificationService = inject(StudentNotificationService);
  private readonly authService = inject(PathwaysAuthService);

  closePanel() {
    this.notificationPanelService.togglePanel(false);
  }

  dismissTuitionNotification(notificationId: string) {
    const payload: PatchNotificationRequest = { action: PatchNotificationRequestActionEnum.complete };
    const masterProfileId = this.authService.getActiveAccountMasterProfileId();
    const bearerToken = this.authService.getBearerToken();

    this.notificationService
      .updateNotification(bearerToken, masterProfileId, notificationId, payload)
      .pipe(
        take(1),
        switchMap(() => this.notificationService.getStudentNotifications(bearerToken, masterProfileId)),
        catchError((error) => {
          console.error('Error occurred while getting the notifications', error);
          return of([]);
        }),
      )
      .subscribe();
  }

  openOppUrl() {
    window.open(environment.STUDENT_APPS_URLS.OPP, '_blank');
  }
}
