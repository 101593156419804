import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[pwClickOutside]',
  standalone: true,
})
export class PwClickOutsideDirective {
  @Output()
  pwClickOutside: EventEmitter<void> = new EventEmitter();

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: PointerEvent) {
    const nativeElement: HTMLElement = this.elementRef.nativeElement;
    const clickedInside: boolean = nativeElement.contains(event.target as Node);

    if (!clickedInside) {
      this.pwClickOutside.emit();
    }
  }

  @HostListener('click', ['$event'])
  public onElementClick(event: PointerEvent) {
    event.stopPropagation(); // Stop the propagation of the click event
  }

  constructor(private elementRef: ElementRef<HTMLElement>) {}
}
