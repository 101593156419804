import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
// eslint-disable-next-line no-var
declare var __webpack_public_path__: string;

@Component({
  selector: 'pw-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent {
  @Input() inputId = '';
  @Input() inputType = '';
  @Input() disabled = false;
  @Input() labelText = '';
  @Input() hintText = '';
  @Input() inputPlaceholder = '';
  @Input() inputValue!: string | number | unknown;
  @Input() icon!: string;
  @Input() min: number = undefined;
  @Input() max: number = undefined;
  @Input() maxlength: number = undefined;
  @Input() invalid = false;
  @Input() errorText!: string;
  @Input() autocomplete: string = undefined;
  @Input() iconTitle = '';
  @Input() iconPosition: 'left' | 'right' = 'right';
  @Input() noMargin = false;
  @Output() inputValueChange: EventEmitter<unknown> = new EventEmitter();
  @Output() fieldBlur: EventEmitter<void> = new EventEmitter();
  @Output() fieldFocus: EventEmitter<void> = new EventEmitter();
  @Output() fieldKeyUp: EventEmitter<void> = new EventEmitter();
  @Output() iconClick: EventEmitter<unknown> = new EventEmitter();

  // Dropdown properties
  public dropdown = false;
  private _dropdownOptions: Array<unknown>;
  @Input()
  get dropdownOptions(): Array<unknown> {
    return this._dropdownOptions;
  }
  set dropdownOptions(dropdownOptions: Array<unknown>) {
    this._dropdownOptions = dropdownOptions;
    this.dropdown = dropdownOptions?.length > 0;
  }
  @Input() dropdownOptionLabel: string;

  public onValueChange(value: string | number | unknown): void {
    this.inputValueChange.emit(value);
  }

  public onIconClick(): void {
    this.iconClick.emit();
  }

  public getInputClasses(): { [className: string]: boolean } {
    return {
      'pw-input--invalid': Boolean(this.invalid || this.errorText),
      'pw-input--disabled': this.disabled,
      'pw-input--with-right-icon': !!this.icon,
    };
  }

  public getIconClasses(): { [className: string]: boolean } {
    return {
      [this.icon]: !this.svgIconSrc(),
      'pw-icon--right': this.iconPosition === 'right',
      'pw-icon--left': this.iconPosition === 'left',
      'pw-input__icon--invalid': Boolean(this.invalid || this.errorText),
      'pw-input__icon--disabled': this.disabled,
    };
  }

  public svgIconSrc(): string {
    return /\.svg$/i.test(this.icon) ? `${__webpack_public_path__}assets/icons/${this.icon}` : '';
  }
}
