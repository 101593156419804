import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable({
  providedIn: 'root',
})
export class PathwaysGoogleTagManagerService extends GoogleTagManagerService {
  async pushTag(item: object): Promise<void> {
    try {
      return await super.pushTag(item);
    } catch (error) {
      console.warn('Error occurred while pushing GA tag. Likely could not load GA script', error);
    }
  }

  async addGtmToDom(): Promise<boolean> {
    try {
      return await super.addGtmToDom();
    } catch (error) {
      console.warn('Error occurred while adding GTM script to DOM', error);
      return false;
    }
  }
}
