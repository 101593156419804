<aside class="public-page__left-panel">
  <img *ngIf="logoSrc" class="public-page__left-panel-logo" [src]="logoSrc" [attr.alt]="logoAlt" />
  <div class="public-page__left-panel-bottom">
    <img *ngIf="sideImageSrc" class="public-page__left-panel-side-image" [src]="sideImageSrc" alt="" />
    <pw-copyright></pw-copyright>
  </div>
</aside>
<main class="public-page__main" [ngClass]="stickyFooter ? 'public-page__main--sticky-footer' : ''">
  <img *ngIf="mobileLogoSrc" class="public-page__main-logo" [src]="mobileLogoSrc" [attr.alt]="logoAlt" />
  <div class="public-page__main-container">
    <div
      class="public-page__main-content"
      [class.public-page__main-content--tou]="template === templatesNames.TERMS_OF_USE"
      [class.public-page__main-content--wide]="wideContent"
    >
      <h1
        *ngIf="pageTitle"
        class="public-page__main-title"
        [class.public-page__main-title--small]="template === templatesNames.MFA"
        [class.centered]="wideContent"
        [innerHtml]="((isMobile$ | async) && mobilePageTitle) || pageTitle"
      ></h1>
      <img *ngIf="mobileImageSrc" class="public-page__mobile-image" [src]="mobileImageSrc" alt="" />
      <ng-container *ngIf="!hideDescriptions">
        <p *ngFor="let description of descriptions" [innerHtml]="description" class="public-page__description" [class.centered]="wideContent"></p>
      </ng-container>
      <ng-content></ng-content>
      <p-divider styleClass="public-page__divider" [class.centered]="wideContent" *ngIf="hasAlternativeBlock()" align="center">Or</p-divider>
      <div #alternativeBlock class="public-page__alternative" [class.centered]="wideContent">
        <ng-content select="[alternative]"></ng-content>
      </div>
    </div>
  </div>
  <ng-content select="[footer]" class="public-page__main-footer"></ng-content>
</main>
