<header class="error-page__header">
  <img *ngIf="logoSrc" class="error-page__logo" [src]="logoSrc" [attr.alt]="logoAlt" />
</header>
<main class="error-page__main">
  <div class="error-page__main-container">
    <p *ngIf="errorCode" class="error-page__error-code" i18n>Error code: [{{ errorCode }}]</p>
    <div class="error-page__description-wrapper">
      <div class="error-page__description">
        <h1 *ngIf="pageTitle" [innerHtml]="pageTitle"></h1>
        <img *ngIf="illustrationSrc" class="error-page__illustration--mobile" [src]="illustrationSrc" alt="" />
        <ng-content></ng-content>
      </div>
      <img *ngIf="illustrationSrc" class="error-page__illustration--desktop" [src]="illustrationSrc" alt="" />
    </div>
  </div>
</main>
