import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Absence } from '@pw/profile-data-access';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'pw-attendance-table',
  standalone: true,
  imports: [CommonModule, TableModule],
  templateUrl: './attendance-table.component.html',
  styleUrls: ['./attendance-table.component.scss'],
})
export class AttendanceTableComponent {
  @Input() absences: Absence[] = [];
}
