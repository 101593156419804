import { Component, Input } from '@angular/core';

@Component({
  selector: 'pw-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent {
  @Input() logoSrc = 'assets/logos/kaplan-pathways-blue-logo.svg';
  @Input() logoAlt = 'Kaplan International Pathways';
  @Input() illustrationSrc = `assets/illustrations/client-error.png`;
  @Input() pageTitle = '';
  @Input() errorCode = '';
}
