import { DatePipe } from '@angular/common';
import { endOfMonth } from 'date-fns';

export const formatMonthlyDates = (filters: (Date | string)[]) => {
  const datePipe = new DatePipe('en-US');
  return datePipe.transform(filters[0], 'MMM yyyy');
};

export const formatWeeklyDates = (filters: (Date | string)[]) => {
  return formatDateRange(filters, 'd MMM yyyy');
};

export const formatOverallDates = (filters: (Date | string)[]) => {
  return formatDateRange(filters, 'MMM yyyy');
};

const formatDateRange = (filters: (Date | string)[], format: string) => {
  let formattedDate = '';
  if (filters.length === 2 && filters[0] && filters[1]) {
    const datePipe = new DatePipe('en-US');
    const formattedStartDate = datePipe.transform(filters[0], format);
    const formattedEndDate = datePipe.transform(filters[1], format);

    formattedDate = `${formattedStartDate} - ${formattedEndDate}`;
  }
  return formattedDate;
};

export const parseMonthlyDate = (formattedDate: string): Date[] => {
  const startDate = new Date(Date.parse(formattedDate));
  const endDate = endOfMonth(startDate);
  return [startDate, endDate];
};

export const parseWeeklyDates = (formattedDate: string): Date[] => {
  const dateRange = formattedDate.split(' - ');
  if (dateRange.length === 2) {
    const startDate = new Date(Date.parse(dateRange[0]));
    const endDate = new Date(Date.parse(dateRange[1]));
    return [startDate, endDate];
  }
  return [];
};

export const areDatesEqual = (date1: string | Date, date2: string | Date): boolean => {
  const d1 = typeof date1 === 'string' ? createDateFromString(date1) : date1;
  const d2 = typeof date2 === 'string' ? createDateFromString(date2) : date2;

  return d1.getTime() === d2.getTime();
};

export const createDateFromString = (dateString: string): Date => {
  const date = new Date(dateString);
  date.setHours(0, 0, 0, 0);
  return date;
};
