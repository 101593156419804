<div class="pw-input" [ngClass]="{ 'pw-form-field--no-margin': noMargin }">
  <label *ngIf="labelText" class="pw-input__label" [for]="inputId">{{ labelText }}</label>
  <div class="pw-input__wrapper">
    <i *ngIf="icon && !svgIconSrc()" class="pw-input__icon" [ngClass]="getIconClasses()" (click)="onIconClick()" [attr.title]="iconTitle"></i>
    <img
      *ngIf="icon && svgIconSrc()"
      class="pw-input__icon"
      [ngClass]="getIconClasses()"
      (click)="onIconClick()"
      [src]="svgIconSrc()"
      [attr.title]="iconTitle"
      [attr.alt]="iconTitle"
    />
    <input
      *ngIf="!dropdown"
      [id]="inputId"
      [type]="inputType"
      [disabled]="disabled"
      [attr.placeholder]="inputPlaceholder"
      [(ngModel)]="inputValue"
      (ngModelChange)="onValueChange($event)"
      (blur)="fieldBlur.emit()"
      (focus)="fieldFocus.emit()"
      (keyup)="fieldKeyUp.emit()"
      [attr.autocomplete]="autocomplete"
      [attr.min]="min"
      [attr.max]="max"
      [ngClass]="getInputClasses()"
      [maxlength]="maxlength"
    />
    <p-dropdown
      *ngIf="dropdown"
      [placeholder]="inputPlaceholder"
      [disabled]="disabled"
      (onBlur)="fieldBlur.emit()"
      (onFocus)="fieldFocus.emit()"
      [options]="dropdownOptions"
      [optionLabel]="dropdownOptionLabel"
      [(ngModel)]="inputValue"
      (ngModelChange)="onValueChange($event)"
    ></p-dropdown>
  </div>

  <span *ngIf="hintText && !errorText" class="pw-input__hint">{{ hintText }}</span>
  <span *ngIf="errorText" class="pw-input__error">{{ errorText }}</span>
</div>
