import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserverService } from '@klg/shared/utils-dom';
import { environment } from '@pw/shared/environment';
import { MenuItem } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DialogModule } from 'primeng/dialog';
import { Observable, Subject, take, takeUntil, tap } from 'rxjs';
import { NotificationPanelService } from '../../services';
import { PrivatePageMainComponent } from '../private-page-main/private-page-main.component';
import { ConfirmUkAddressContentComponent } from './confirm-uk-address-content/confirm-uk-address-content.component';
import {
  PatchNotificationRequest,
  PatchNotificationRequestAccommodationRenter,
  PatchNotificationRequestAccommodationRenterEnum,
  PatchNotificationRequestAccommodationType,
  PatchNotificationRequestAccommodationTypeEnum,
  PatchNotificationRequestActionEnum,
  StudentNotificationService,
  TaskItemNameEnum,
} from '@pw/notification-data-access';
import { PathwaysAuthService } from '@pw/account/auth';

export interface ConfirmAddressOption {
  key: string;
  name: string;
  desc?: string;
  value: PatchNotificationRequestAccommodationType | PatchNotificationRequestAccommodationRenter;
}

@Component({
  selector: 'pw-confirm-uk-address',
  standalone: true,
  imports: [CommonModule, BreadcrumbModule, PrivatePageMainComponent, ConfirmUkAddressContentComponent, DialogModule],
  templateUrl: './confirm-uk-address.component.html',
  styleUrls: ['./confirm-uk-address.component.scss'],
})
export class ConfirmUkAddressComponent implements OnInit, OnDestroy {
  isMobile$: Observable<boolean>;
  breadcrumbHome: MenuItem | undefined;
  breadcrumbItem: MenuItem | undefined;
  kaplanLivingUrl = environment.STUDENT_APPS_URLS.STAR_REZ;

  isFirstStep = true;
  firstStepOptions: ConfirmAddressOption[] = [
    {
      key: 'will-book-kaplan-living',
      name: 'I plan to book my accommodation with Kaplan Living',
      desc: `Please check <a href="` + this.kaplanLivingUrl + `" target="_blank">Kaplan living’s accommodation options</a>`,
      value: PatchNotificationRequestAccommodationTypeEnum.book_kaplan_living_accommodation,
    },
    {
      key: 'booked-kaplan-living',
      name: 'I have booked accommodation with Kaplan Living',
      value: PatchNotificationRequestAccommodationTypeEnum.booked_kaplan_living_accommodation,
    },
    {
      key: 'will-book-short-private',
      name: 'I will book / have booked short-term private accommodation',
      desc: 'For example, you are staying in a hotel or with a friend for a short time during your studies.',
      value: PatchNotificationRequestAccommodationTypeEnum.booked_short_term_private_accommodation,
    },
    {
      key: 'will-book-long-private',
      name: 'I will book / have booked long-term private accommodation',
      desc: 'For example, you are renting privately or staying with family during the entire length of your studies.',
      value: PatchNotificationRequestAccommodationTypeEnum.booked_long_term_private_accommodation,
    },
  ];
  secondStepOptions: Partial<ConfirmAddressOption>[] = [
    {
      key: 'name-on-rental-contract-yes',
      name: 'Yes, my name is on the rental contract',
      value: PatchNotificationRequestAccommodationRenterEnum.student,
    },
    {
      key: 'name-on-rental-contract-no',
      name: 'No, it has the name of the person I stay with',
      value: PatchNotificationRequestAccommodationRenterEnum.third_party,
    },
  ];

  private readonly taskUrlQuery = '?referral_source=student_account';
  private readonly addressConfirmationUrl = environment.PRIVATE_ACCOMMODATION_URL;
  private readonly destroy$ = new Subject<void>();
  private readonly breakpointObserverService = inject(BreakpointObserverService);
  private readonly notificationPanelService = inject(NotificationPanelService);
  private readonly studentNotificationService = inject(StudentNotificationService);
  private readonly authService = inject(PathwaysAuthService);

  constructor() {
    this.isMobile$ = this.breakpointObserverService.isMobile$;
    this.resetBreadcrumb();
  }

  ngOnInit(): void {
    this.isMobile$
      .pipe(
        tap((isMobile) => this.notificationPanelService.togglePanel(!isMobile)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  resetBreadcrumb() {
    this.breadcrumbItem = {
      icon: 'pi pi-arrow-left',
      label: $localize`Notifications`,
      command: () => {
        this.notificationPanelService.toggleConfirmUkAddress(false);
        this.notificationPanelService.togglePanel(true);
      },
    };
    this.breadcrumbHome = { ...this.breadcrumbItem };
  }

  handleCancel() {
    this.notificationPanelService.toggleConfirmUkAddress(false);
    this.isMobile$
      .pipe(
        take(1),
        tap(() => this.notificationPanelService.togglePanel(true)),
      )
      .subscribe();
  }

  handleConfirm([firstOptionSelected, secondOptionSelected, isFinalOption]: [ConfirmAddressOption, ConfirmAddressOption, boolean]) {
    if (!isFinalOption) {
      this.breadcrumbItem.label = firstOptionSelected.name;
      this.breadcrumbItem.command = () => {
        this.isFirstStep = true;
        this.resetBreadcrumb();
      };
      this.breadcrumbHome = { ...this.breadcrumbItem };
    }

    if (isFinalOption) {
      if (secondOptionSelected) {
        window.open(`${this.addressConfirmationUrl}${this.taskUrlQuery}`, '_blank');
      }

      const { id: notificationId } = this.studentNotificationService.getPreArrivalTasks();
      const { id: taskId } = this.studentNotificationService.findPreArrivalTask(TaskItemNameEnum.address_confirmation);
      const payload: PatchNotificationRequest = {
        taskId,
        action: PatchNotificationRequestActionEnum.complete,
        accommodationType: firstOptionSelected.value as PatchNotificationRequestAccommodationType,
        accommodationRenter: secondOptionSelected?.value as PatchNotificationRequestAccommodationRenter,
      };

      this.studentNotificationService
        .updateNotification(this.authService.getBearerToken(), this.authService.getActiveAccountMasterProfileId(), notificationId, payload)
        .pipe(take(1))
        .subscribe();

      this.handleCancel();
    }
  }
}
