import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'pw-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent {
  @Input() mainPage = '';

  constructor(private router: Router) {}

  public navigateToMainPage(): void {
    this.router.navigate(['/', this.mainPage]);
  }
}
