import { BrowserCacheLocation, Configuration, LogLevel } from '@azure/msal-browser';
import { ReportingRoutes } from '@pw/shared/environment';
import { environment } from '@pw/shared/environment';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = (appBaseHref?: string): Configuration => ({
  auth: {
    clientId: environment.AZURE_AD_APPLICATION_ID,
    authority: 'https://login.microsoftonline.com/057daf85-b1d5-44cd-ab7b-0a4ce1b29eae',
    redirectUri: appBaseHref ?? '/', // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
    postLogoutRedirectUri: appBaseHref ? `${appBaseHref}${ReportingRoutes.LOGOUT}` : `${ReportingRoutes.LOGOUT}`, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        switch (logLevel) {
          case LogLevel.Error:
            console.error(message);
            break;

          case LogLevel.Warning:
            console.warn(message);
            break;

          case LogLevel.Info:
            // eslint-disable-next-line no-restricted-syntax
            console.info(message);
            break;

          default:
            // eslint-disable-next-line no-restricted-syntax
            console.debug(logLevel, message);
            break;
        }
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
    allowRedirectInIframe: true,
  },
});

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
export const silentRequest = {
  scopes: ['openid', 'profile'],
  loginHint: 'example@domain.net',
};
