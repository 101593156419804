<img alt="Edit icon" [ngSrc]="editImg" width="24" height="24" />
<div class="payments-due-notification">
  <div class="payments-due-notification__header">
    <span class="payments-due-notification__title">Payments</span>
    <span class="payments-due-notification__subtitle">A payment status may not update immediately. Please check in a few days.</span>
  </div>
  <ng-container *ngFor="let payment of payments; first as isFirst">
    <pw-payments-due-box [payment]="payment" [isFirst]="isFirst"></pw-payments-due-box>
  </ng-container>
  <div class="payments-due-notification__footer">
    <span class="payments-due-notification__subtitle">* Payments that show as 'due soon' are due within 5 days of today's date.</span>
    <span class="payments-due-notification__subtitle">See something that doesn't look right? <a [href]="'mailto:' + supportEmail" i18n>Contact us</a></span>
  </div>
</div>
