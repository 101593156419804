<pw-attendance-filter
  [filterId]="'attendance-filter-title-' + attendanceStat?.frequency"
  [frequency]="attendanceStat?.frequency"
  [showFilter]="showFilter"
  [menuItems]="menuItems"
></pw-attendance-filter>
<div
  *ngIf="isDataAvailable; else noPercentage"
  [id]="'attendance-percentage-' + attendanceStat?.frequency"
  class="attendance-percentage__percentage"
  [ngClass]="'attendance-percentage__percentage--' + attendanceLevel"
>
  {{ attendanceStat?.percentage | number : '1.0-0' }}%
</div>
<ng-template #noPercentage>
  <div class="attendance-percentage__no-data" i18n>No data available yet</div>
</ng-template>
<div [id]="'attendance-percentage-dates-' + attendanceStat?.frequency" class="attendance-percentage__dates">{{ attendanceStat?.filterDates }}</div>
