import { ModuleWithProviders, Type } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { LoggerModule, NgxLoggerLevel, TOKEN_LOGGER_SERVER_SERVICE } from 'ngx-logger';
import { CustomisedNGXLoggerServerService } from '../services/customised-ngx-logger-server.service';

export const APPLICATION_IMPORTS: Array<Type<unknown> | ModuleWithProviders<unknown> | unknown[]> = [
  BrowserModule,
  BrowserAnimationsModule,
  HttpClientModule,
  LoggerModule.forRoot(
    {
      level: NgxLoggerLevel.DEBUG,
      disableConsoleLogging: false,
      serverLogLevel: NgxLoggerLevel.FATAL,
    },
    {
      serverProvider: {
        provide: TOKEN_LOGGER_SERVER_SERVICE,
        useClass: CustomisedNGXLoggerServerService,
      },
    },
  ),
];
