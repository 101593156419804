import { inject, Injectable } from '@angular/core';
import { Memoize } from '@klg/shared/utils-http';
import { SAAttendancesApiService } from '@pw/api/profile';
import { PathwaysGoogleTagManagerService } from '@pw/shared/google-tag-manager';
import { GtmEventNames } from '@pw/shared/types';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, catchError, EMPTY, Observable, of, tap } from 'rxjs';
import {
  areDatesEqual,
  createDateFromString,
  formatMonthlyDates,
  formatOverallDates,
  formatWeeklyDates,
  parseMonthlyDate,
  parseWeeklyDates,
} from '../functions';
import { AttendanceResult, AttendanceStat, AttendanceSummary, AttendanceTypeEnum } from '../models';

@Injectable({
  providedIn: 'root',
})
export class StudentAttendanceService {
  attendanceResult$: Observable<AttendanceResult>;
  attendanceStats$: Observable<AttendanceStat[]>;
  currentMonthlyFilters$: Observable<Date[]>;
  currentWeeklyFilters$: Observable<Date[]>;
  activeTabIndex$: Observable<number>;
  attendancesError$: Observable<string>;

  monthlyFilters: Date[][] = [];
  weeklyFilters: Date[][] = [];

  private attendanceResult = new BehaviorSubject<AttendanceResult>(null);
  private attendanceStats = new BehaviorSubject<AttendanceStat[]>([]);
  private currentMonthlyFilters = new BehaviorSubject<Date[]>([]);
  private currentWeeklyFilters = new BehaviorSubject<Date[]>([]);
  private activeTabIndex = new BehaviorSubject<number>(0);
  private attendancesError = new BehaviorSubject<string>(undefined);

  private readonly attendanceService = inject(SAAttendancesApiService);
  private readonly gtmService = inject(PathwaysGoogleTagManagerService);

  constructor() {
    this.attendanceResult$ = this.attendanceResult.asObservable();
    this.attendanceStats$ = this.attendanceStats.asObservable();
    this.currentMonthlyFilters$ = this.currentMonthlyFilters.asObservable();
    this.currentWeeklyFilters$ = this.currentWeeklyFilters.asObservable();
    this.activeTabIndex$ = this.activeTabIndex.asObservable();
    this.attendancesError$ = this.attendancesError.asObservable();
  }

  @Memoize((masterProfileId: string) => masterProfileId)
  getAttendances(bearerToken: string, masterProfileId: string) {
    return this.attendanceService.getAttendances(bearerToken, masterProfileId).pipe(
      tap((attendanceResult) => {
        this.attendanceResult.next(attendanceResult);
        this.setInitialStats(attendanceResult);
        this.setFilters(attendanceResult);
      }),
      catchError((error: Error) => {
        console.error('Error occurred while getting attendances', error);
        this.attendancesError.next(error.message);
        return of(EMPTY);
      }),
    );
  }

  setCurrentFilters(frequency: string, formattedDate: string) {
    let filters: Date[];
    switch (frequency) {
      case AttendanceTypeEnum.monthly:
        filters = parseMonthlyDate(formattedDate);
        this.currentMonthlyFilters.next(filters);
        this.gtmService.pushTag({ event: GtmEventNames.DETAIL_ATTENDANCE_MONTHLY_FILTER });
        break;
      case AttendanceTypeEnum.weekly:
        filters = parseWeeklyDates(formattedDate);
        this.currentWeeklyFilters.next(filters);
        this.gtmService.pushTag({ event: GtmEventNames.DETAIL_ATTENDANCE_WEEKLY_FILTER });
        break;
    }
    this.setCurrentStats(filters, frequency);
  }

  private setInitialStats(attendanceResult: AttendanceResult) {
    attendanceResult.monthly.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());
    const mostRecentMonthlyStats = attendanceResult.monthly[0];
    const monthly: AttendanceStat = {
      frequency: AttendanceTypeEnum.monthly,
      percentage: mostRecentMonthlyStats?.attendance,
      filterDates: formatMonthlyDates([mostRecentMonthlyStats?.startDate]),
      hasAbsence: mostRecentMonthlyStats?.absences.length > 0,
    };

    attendanceResult.weekly.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());
    const mostRecentWeeklyStats = attendanceResult.weekly[0];
    const weekly: AttendanceStat = {
      frequency: AttendanceTypeEnum.weekly,
      percentage: mostRecentWeeklyStats?.attendance,
      filterDates: formatWeeklyDates([mostRecentWeeklyStats?.startDate, mostRecentWeeklyStats?.endDate]),
      hasAbsence: mostRecentWeeklyStats?.absences.length > 0,
    };

    const overall: AttendanceStat = {
      frequency: AttendanceTypeEnum.overall,
      percentage: attendanceResult.overall.attendance,
      filterDates: formatOverallDates([attendanceResult.overall.startDate, attendanceResult.overall.endDate]),
      hasAbsence: attendanceResult.overall.absences.length > 0,
    };

    const noDataAvailable = attendanceResult.overall.attendance === 0 && attendanceResult.overall.absences.length === 0;

    if (noDataAvailable) {
      this.attendanceStats.next(undefined);
    } else {
      this.attendanceStats.next([monthly, weekly, overall]);
    }
  }

  private setFilters(attendanceResult: AttendanceResult) {
    this.monthlyFilters = attendanceResult.monthly.map((attendanceSummary) => {
      const startDate = createDateFromString(attendanceSummary.startDate);
      const endDate = createDateFromString(attendanceSummary.endDate);
      return [startDate, endDate];
    });
    this.weeklyFilters = attendanceResult.weekly.map((attendanceSummary) => {
      const startDate = createDateFromString(attendanceSummary.startDate);
      const endDate = createDateFromString(attendanceSummary.endDate);
      return [startDate, endDate];
    });
    this.currentMonthlyFilters.next(this.monthlyFilters[0]);
    this.currentWeeklyFilters.next(this.weeklyFilters[0]);
  }

  private setCurrentStats(dates: Date[], frequency: string) {
    let attendanceSummary: AttendanceSummary;
    const newStat = {} as AttendanceStat;
    const currentStats = this.attendanceStats.value;
    let newStats: AttendanceStat[] = [];
    switch (frequency) {
      case AttendanceTypeEnum.weekly:
        attendanceSummary = this.attendanceResult.value.weekly.find((summary) => areDatesEqual(summary.startDate, dates[0]));
        newStat.frequency = AttendanceTypeEnum.weekly;
        newStat.percentage = attendanceSummary.attendance;
        newStat.filterDates = formatWeeklyDates([attendanceSummary.startDate, attendanceSummary.endDate]);
        newStat.hasAbsence = attendanceSummary.absences.length > 0;
        break;
      case AttendanceTypeEnum.monthly:
        attendanceSummary = this.attendanceResult.value.monthly.find((summary) => areDatesEqual(summary.startDate, dates[0]));
        newStat.frequency = AttendanceTypeEnum.monthly;
        newStat.percentage = attendanceSummary.attendance;
        newStat.filterDates = formatMonthlyDates([attendanceSummary.startDate, attendanceSummary.endDate]);
        newStat.hasAbsence = attendanceSummary.absences.length > 0;
        break;
    }
    newStats = currentStats.map((stat) => (stat.frequency === frequency ? newStat : stat));
    this.attendanceStats.next(newStats);
  }

  getMenuItemsByFrequency(frequency: string): MenuItem[] {
    switch (frequency) {
      case AttendanceTypeEnum.monthly:
        return this.monthlyFilters.map((value) => ({ label: formatMonthlyDates(value) }));
      case AttendanceTypeEnum.weekly:
        return this.weeklyFilters.map((value) => ({ label: formatWeeklyDates(value) }));
    }
  }

  setTabIndex(index: number) {
    this.activeTabIndex.next(index);
  }
}
