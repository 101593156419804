import { HttpErrorResponse } from '@angular/common/http';
import { parseApiError } from '@klg/shared/logger';

export function parsePwApiError(response: HttpErrorResponse | string) {
  if (!response) return '';
  if (typeof response === 'string') return response;
  const isPathwaysApiError = Array.isArray(response?.error?.error) && response?.error?.message;

  if (!response.error || !isPathwaysApiError) {
    return parseApiError(response);
  }

  if (response.error && isPathwaysApiError) {
    const message = response.error.message;
    const errors: Array<{ fieldName: string; message: string }> = response.error.error;

    const fieldsErrors = errors.reduce<string>((accumulator, currentError) => {
      if (accumulator.length === 0) {
        const combinedErrors = currentError.message.trim();
        return combinedErrors;
      }

      const combinedErrors = `${accumulator}, ${currentError.message}`.trim();

      return combinedErrors;
    }, '');

    let finalMessage = `${message}. Errors: ${fieldsErrors}`;

    if (finalMessage.length > 120) {
      finalMessage = finalMessage.substring(0, 120).concat('...');
    }

    return finalMessage;
  }
}
