import { BrowserCacheLocation, Configuration, LogLevel } from '@azure/msal-browser';
import { environment } from '@pw/shared/environment';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1 || window.navigator.userAgent.indexOf('Edg/') > -1;
/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = (appBaseHref: string): Configuration => ({
  auth: {
    clientId: environment.B2C_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
    authority: `${environment.B2C_BASE_PATH}${environment.B2C_CUSTOM_POLICIES.SIGN_IN}`,
    knownAuthorities: [environment.B2C_DOMAIN], // Mark your B2C tenant's domain as trusted.
    redirectUri: appBaseHref ?? '/', // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
    postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge. isIE
  },
  system: {
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        switch (logLevel) {
          case LogLevel.Error:
            console.error(message);
            break;

          case LogLevel.Warning:
            console.warn(message);
            break;

          case LogLevel.Info:
            // eslint-disable-next-line no-restricted-syntax
            console.info(message);
            break;

          default:
            // eslint-disable-next-line no-restricted-syntax
            console.debug(logLevel, message);
            break;
        }
      },
      logLevel: environment.B2C_LOG_LEVEL,
      piiLoggingEnabled: false,
    },
    allowRedirectInIframe: true,
  },
});

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
export const silentRequest = {
  scopes: ['openid', 'profile'],
  loginHint: 'example@domain.net',
};
