import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, RedirectRequest } from '@azure/msal-browser';
import { ReportingRoutes } from '@pw/shared/environment';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { silentRequest } from '../config/auth-config';
import { UserRoles } from '../models/user-role.enum';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class ReportingAuthService {
  private isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoggedIn$: Observable<boolean> = this.isLoggedIn.asObservable();
  public logoutInProgress = false;
  private authenticationResult: AuthenticationResult | null = null;

  constructor(private router: Router, private azureAuthService: MsalService, @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration) {}

  azureLogin(): void {
    if (this.msalGuardConfig.authRequest) {
      this.azureAuthService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.azureAuthService.loginRedirect();
    }
  }

  azureLogout(): void {
    const account = this.getActiveAccount();
    if (!this.logoutInProgress) {
      this.logoutInProgress = true;
      this.azureAuthService.logout({ account }).subscribe({
        next: () => {
          this.logoutInProgress = false;
        },
        error: () => {
          this.logoutInProgress = false;
        },
      });
    }
  }

  ssoSilent(): Observable<void> {
    return from(
      this.azureAuthService.instance
        .acquireTokenSilent({
          ...silentRequest,
          account: this.getActiveAccount(),
        })
        .then((result: AuthenticationResult) => {
          this.authenticationResult = result;
        })
        .catch((error) => {
          console.error('Error occurred while acquiring token', error);
          this.authenticationResult = null;
        }),
    );
  }

  getBearerToken(): string {
    return `Bearer ${this.getIdToken()}`;
  }

  getIdToken(): string {
    return this.authenticationResult?.idToken;
  }

  getUser(): User | null {
    const idTokenClaims = this.getActiveAccount()?.idTokenClaims;
    if (idTokenClaims) {
      const user = {
        name: idTokenClaims.name,
        email: idTokenClaims.preferred_username,
        roles: idTokenClaims.roles ?? [],
        institutions: this.getUserInstitutions(idTokenClaims.roles) ?? [],
        isAdmin: this.hasAdminRole(idTokenClaims.roles),
      } as User;
      return user;
    }
    return null;
  }

  localLogout() {
    this.router.navigate([ReportingRoutes.LOGOUT]);
  }

  checkAndSetActiveAccount() {
    const activeAccount = this.getActiveAccount();

    if (!activeAccount && this.getAllAccounts().length > 0) {
      this.setActiveAccount(this.getAllAccounts()[0]);
    }
  }

  getActiveAccount() {
    const activeAccount = this.azureAuthService.instance.getActiveAccount();
    return activeAccount || (this.getAllAccounts().length ? this.getAllAccounts()[0] : null);
  }

  setActiveAccount(account) {
    this.azureAuthService.instance.setActiveAccount(account);
  }

  getAuthenticationResult(): AuthenticationResult {
    return this.authenticationResult;
  }

  setAuthenticationResult(authenticationResult: AuthenticationResult) {
    this.authenticationResult = authenticationResult;
  }

  getAllAccounts() {
    return this.azureAuthService.instance.getAllAccounts() ?? [];
  }

  private getUserInstitutions(roles: string[]) {
    return roles?.filter((role) => role.includes('institution')).map((role) => Number(role.split('_').pop())) ?? [];
  }

  private hasAdminRole(roles: string[]): boolean {
    return roles?.some((role: string) => role.includes(UserRoles.ADMIN));
  }
}
