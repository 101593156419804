<pw-card-shell>
  <ng-container *ngIf="showCarousel; else isDesktop">
    <pw-attendance-carousel [attendanceStats]="attendanceStats"></pw-attendance-carousel>
  </ng-container>

  <ng-template #isDesktop>
    <div class="attendance-card__desktop">
      <ng-container *ngFor="let stat of attendanceStats; let isLast = last">
        <pw-attendance-percentage [attendanceStat]="stat"></pw-attendance-percentage>
        <p-divider *ngIf="!isLast" class="attendance-statistics__divider" layout="vertical" />
      </ng-container>
    </div>
  </ng-template>
</pw-card-shell>
