import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ReportingRoutes } from '@pw/shared/environment';
import { ReportingAuthService } from '../services/auth.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authService: ReportingAuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        const currentUrl = this.router.url;
        if (error?.status === 401 && !currentUrl.includes(ReportingRoutes.LOGOUT)) {
          this.authService.azureLogout();
        }
        return throwError(() => error);
      }),
    );
  }
}
