export enum CollegeCode {
  BIRMINGHAM_IPC = 'UBP',
  BOURNEMOUTH_UNI_INT_COL = 'BUIntCol',
  BRISTOL_IPC = 'IPBRISL',
  GLASGOW_INT_COL = 'GIC',
  KAPLAN_INTERNATIONAL_PATHWAYS = 'KIP',
  KAPLAN_INT_COLLEGE_LONDON = 'KICL',
  NOTTINGHAM_TRENT_INT_COL = 'NTIC',
  UNI_OF_BRIGHTON_INT_COL = 'UBIC',
  UNI_OF_ESSEX_INT_COL = 'UEIC',
  UNI_OF_LIVERPOOL_INT_COL = 'LIC',
  UNI_OF_NOTTINGHAM_INT_COL = 'TUNIC',
  UWE_BRISTOL_INT_COL = 'UWEBIC',
  YORK_IPC = 'UYIPC',
}

export interface College {
  code: CollegeCode;
  name?: string;
  infoUrl?: string;
  absenceFormUrl?: string;
  serviceEmail?: string;
}

export const colleges: College[] = [
  { code: CollegeCode.BIRMINGHAM_IPC },
  {
    code: CollegeCode.BOURNEMOUTH_UNI_INT_COL,
    name: 'Bournemouth University<br/>International College',
    infoUrl: 'https://rise.articulate.com/share/Xaq3WMubh30tF7X1VCy3IzXiAdKFjf1K#/',
    absenceFormUrl: 'https://kicpathways.formstack.com/forms/buintcol_absenceform',
    serviceEmail: 'buintcolcollegeservices@kaplan.com',
  },
  { code: CollegeCode.BRISTOL_IPC },
  {
    code: CollegeCode.GLASGOW_INT_COL,
    name: 'Glasgow International College',
    infoUrl: 'https://rise.articulate.com/share/JKErh769wzgN8VnqxlBnk79Pk8PlkyNl#/',
    absenceFormUrl: 'http://kicpathways.formstack.com/forms/missedclassform',
    serviceEmail: 'contact.gic@kaplan.com',
  },
  {
    code: CollegeCode.KAPLAN_INTERNATIONAL_PATHWAYS,
    name: 'Kaplan International Pathways',
    absenceFormUrl: 'https://kicpathways.formstack.com/forms/digitalpathways_absenceform',
    serviceEmail: 'digitalpathwayscollegeservices@kaplan.com',
  },
  {
    code: CollegeCode.KAPLAN_INT_COLLEGE_LONDON,
    name: 'Kaplan International College London',
    infoUrl: 'https://rise.articulate.com/share/QfzFwlQFGxrw4wwri0x9oz8ou8rthyVL#/',
    absenceFormUrl: 'https://kicpathways.formstack.com/forms/absenceform',
    serviceEmail: 'kicl.studentservices@kaplan.com',
  },
  {
    code: CollegeCode.NOTTINGHAM_TRENT_INT_COL,
    name: 'Nottingham Trent<br/>International College',
    infoUrl: 'https://rise.articulate.com/share/iJjamJ5SDvW5LlUNDMt8Xc7l2Y8JQfxX#/',
    absenceFormUrl: 'https://kicpathways.formstack.com/forms/ntic_missed_class_form',
    serviceEmail: 'NTIC.collegeservices@kaplan.com',
  },
  {
    code: CollegeCode.UNI_OF_BRIGHTON_INT_COL,
    name: 'University of Brighton<br/>International College',
    infoUrl: 'https://rise.articulate.com/share/Gqdn4gHyO9-LWNHR3_CLDTE18aeuzeT4#/',
    absenceFormUrl: 'https://kicpathways.formstack.com/forms/ubic_absence_notification',
    serviceEmail: 'UBIC.StudentServices@Kaplan.com',
  },
  {
    code: CollegeCode.UNI_OF_ESSEX_INT_COL,
    name: 'University of Essex<br/>International College',
    infoUrl: 'https://rise.articulate.com/share/0ylq3GHFE9yuRvoq8bqlzaKhq21uwsnM#/',
    absenceFormUrl: 'https://kicpathways.formstack.com/forms/ueic_absenceform',
    serviceEmail: 'ueiccollegeservices@kaplan.com',
  },
  {
    code: CollegeCode.UNI_OF_LIVERPOOL_INT_COL,
    name: 'University of Liverpool<br/>International College',
    infoUrl: 'https://rise.articulate.com/share/jg7p0SADTSnoBiKDcPJKt-8djLOOntaC#/',
    absenceFormUrl: 'https://kicpathways.formstack.com/forms/lic_absenceform',
    serviceEmail: 'UoLIC.Hub@kaplan.com',
  },
  {
    code: CollegeCode.UNI_OF_NOTTINGHAM_INT_COL,
    name: 'University of Nottingham<br/>International College',
    infoUrl: 'https://rise.articulate.com/share/AccLMJsqyyz_pmlG0zF1nuLfYZ2CoF0x#/',
    absenceFormUrl: 'https://kicpathways.formstack.com/forms/tunic_absence_notification',
    serviceEmail: 'TUNICCollegeServices@kaplan.com',
  },
  {
    code: CollegeCode.UWE_BRISTOL_INT_COL,
    name: "UWE Bristol's<br/>International College",
    infoUrl: 'https://rise.articulate.com/share/jHM91DeFGE3dcNcLreRgthTEn4jMqzAy#/',
    absenceFormUrl: 'https://kicpathways.formstack.com/forms/uwebic_absence_notification',
    serviceEmail: 'uwebic.collegeservices@kaplan.com',
  },
  {
    code: CollegeCode.YORK_IPC,
    name: 'University of York<br/>International Pathway College',
    infoUrl: 'https://rise.articulate.com/share/ThYAVfRWq612B66mMihfFjJPNjW6qcJX#/',
  },
];
