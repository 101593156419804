<div class="pre-arrival-info-notification">
  <img alt="Eye icon" [ngSrc]="eyeImg" width="24" height="24" />
  <div class="pre-arrival-info-notification__content">
    <div class="pre-arrival-info-notification__title">Pre-arrival guide and pathway course preparation</div>
    <pw-pre-arrival-info-notification
      *ngIf="preArrivalInfoNotificationData"
      [preArrivalInfoNotificationData]="preArrivalInfoNotificationData"
    ></pw-pre-arrival-info-notification>
    <pw-preview *ngIf="pwPreviewInfoNotificationData"></pw-preview>
  </div>
</div>
