/**
 * Student Notifications API
 * Student notification
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface TaskItemApiDto {
  id: string;
  name: TaskItemApiDto.NameApiDtoEnum;
  status: TaskItemApiDto.StatusApiDtoEnum;
  type: TaskItemApiDto.TypeApiDtoEnum;
  completionDate: string;
  createdAt: string;
  modifiedAt: string;
}
export namespace TaskItemApiDto {
  export type NameApiDtoEnum = 'online_registration' | 'address_confirmation' | 'college_arrival_confirmation';
  export const NameApiDtoEnum = {
    online_registration: 'online_registration' as NameApiDtoEnum,
    address_confirmation: 'address_confirmation' as NameApiDtoEnum,
    college_arrival_confirmation: 'college_arrival_confirmation' as NameApiDtoEnum,
  };
  export type StatusApiDtoEnum = 'new' | 'completed';
  export const StatusApiDtoEnum = {
    new: 'new' as StatusApiDtoEnum,
    completed: 'completed' as StatusApiDtoEnum,
  };
  export type TypeApiDtoEnum = 'external' | 'internal';
  export const TypeApiDtoEnum = {
    external: 'external' as TypeApiDtoEnum,
    internal: 'internal' as TypeApiDtoEnum,
  };
}
