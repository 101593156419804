/**
 * Student Notifications API
 * Student notification
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface PatchNotificationRequestApiDto {
  taskId?: string;
  action: PatchNotificationRequestApiDto.ActionApiDtoEnum;
  accommodationType?: PatchNotificationRequestApiDto.AccommodationTypeApiDtoEnum;
  accommodationRenter?: PatchNotificationRequestApiDto.AccommodationRenterApiDtoEnum;
}
export namespace PatchNotificationRequestApiDto {
  export type ActionApiDtoEnum = 'complete';
  export const ActionApiDtoEnum = {
    complete: 'complete' as ActionApiDtoEnum,
  };
  export type AccommodationTypeApiDtoEnum =
    | 'book_kaplan_living_accommodation'
    | 'booked_kaplan_living_accommodation'
    | 'booked_short_term_private_accommodation'
    | 'booked_long_term_private_accommodation';
  export const AccommodationTypeApiDtoEnum = {
    book_kaplan_living_accommodation: 'book_kaplan_living_accommodation' as AccommodationTypeApiDtoEnum,
    booked_kaplan_living_accommodation: 'booked_kaplan_living_accommodation' as AccommodationTypeApiDtoEnum,
    booked_short_term_private_accommodation: 'booked_short_term_private_accommodation' as AccommodationTypeApiDtoEnum,
    booked_long_term_private_accommodation: 'booked_long_term_private_accommodation' as AccommodationTypeApiDtoEnum,
  };
  export type AccommodationRenterApiDtoEnum = 'student' | 'third_party';
  export const AccommodationRenterApiDtoEnum = {
    student: 'student' as AccommodationRenterApiDtoEnum,
    third_party: 'third_party' as AccommodationRenterApiDtoEnum,
  };
}
