<p-table [value]="absences" [tableStyle]="{ 'min-width': '65rem' }">
  <ng-template pTemplate="caption">Absences</ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th>Date</th>
      <th>Time</th>
      <th>Type</th>
      <th>Name</th>
      <th>Code</th>
      <th>Teacher Name</th>
      <th>Authorised</th>
      <th>Absence reason</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-absence>
    <tr>
      <td>{{ absence.date | date : 'dd/MM/yyyy' }}</td>
      <td>{{ absence.startTime }} - {{ absence.endTime }}</td>
      <td>{{ absence.type }}</td>
      <td>{{ absence.name }}</td>
      <td>{{ absence.code }}</td>
      <td>
        <a [href]="'mailto:' + absence.teacherEmail">
          {{ absence.teacherFullName }}
        </a>
      </td>
      <td>{{ absence.absenceAuthorized ? 'Yes' : 'No' }}</td>
      <td>{{ absence.absenceType }}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary">Rows: {{ absences?.length }}</ng-template>
</p-table>
