<div class="radio-buttons__options">
  <div *ngFor="let option of options" class="radio-buttons__option">
    <p-radioButton
      [inputId]="option.key"
      [name]="radioButtonName"
      [value]="option"
      [(ngModel)]="optionSelected"
      (onClick)="selectedOption.emit(optionSelected)"
    ></p-radioButton>
    <div class="radio-buttons__option-label">
      <label [for]="option.key" class="radio-buttons__option-label-name">{{ option.name }}</label>
      <span *ngIf="option.desc" class="radio-buttons__option-label-desc" [innerHTML]="option.desc"></span>
    </div>
  </div>
</div>
