<pw-input
  [inputId]="inputId"
  [labelText]="labelText"
  [inputValue]="inputValue"
  [inputType]="showPassword ? 'text' : 'password'"
  [icon]="showPassword ? 'eye-slash.svg' : 'eye.svg'"
  [disabled]="disabled"
  [iconTitle]="showPassword ? 'Hide password' : 'Show password'"
  [inputPlaceholder]="placeholder"
  [hintText]="hintText"
  [invalid]="invalid"
  [errorText]="errorText"
  [autocomplete]="newPassword ? 'new-password' : 'password'"
  (inputValueChange)="inputValueChange.emit($event)"
  (fieldBlur)="fieldBlur.emit($event)"
  (fieldFocus)="fieldFocus.emit($event)"
  (iconClick)="toggleShowPassword()"
></pw-input>
