import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { ReportingRoutes } from '@pw/shared/environment';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ReportingAuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class LogoutGuard implements CanActivate {
  constructor(private authService: ReportingAuthService, private router: Router, private msalBroadcastService: MsalBroadcastService) {}

  canActivate(): Observable<boolean> {
    return this.msalBroadcastService.msalSubject$.pipe(
      filter((event: EventMessage) => event.eventType === EventType.HANDLE_REDIRECT_END),
      map(() => {
        const user = this.authService.getUser();
        if (user) {
          this.router.navigate([ReportingRoutes.INCIDENTS]);
          return false;
        }
        return true;
      }),
    );
  }
}
