<main>
  <div class="private-page__logo-title" [class.private-page__logo-title--flex-column]="isFlexColumn">
    <div *ngIf="collegeCode && collegeName" class="private-page__logo-container">
      <img [alt]="collegeName" [src]="'assets/college-logos/' + collegeCode + '.svg'" />
    </div>
    <h1 *ngIf="pageTitle" class="private-page__title" [innerHtml]="pageTitle"></h1>
  </div>
  <h2 *ngIf="pageSubtitle" class="private-page__subtitle" [innerHtml]="pageSubtitle"></h2>
  <ng-content></ng-content>
</main>
