import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AttendanceStat, AttendanceTypeEnum } from '@pw/profile-data-access';
import { PathwaysAccountRoutes } from '@pw/shared/environment';
import { PathwaysGoogleTagManagerService } from '@pw/shared/google-tag-manager';
import { GtmEventNames } from '@pw/shared/types';
import { Carousel, CarouselModule } from 'primeng/carousel';
import { AttendancePercentageComponent } from '../../atoms';

// based on PrimeNG code (https://github.com/primefaces/primeng/blob/15.2.0/src/app/components/carousel/carousel.ts#L322)
type onPageChangeEvent = { page: number };

@Component({
  selector: 'pw-attendance-carousel',
  standalone: true,
  imports: [CommonModule, CarouselModule, AttendancePercentageComponent],
  templateUrl: './attendance-carousel.component.html',
  styleUrls: ['./attendance-carousel.component.scss'],
})
export class AttendanceCarouselComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('carousel', { static: true }) carousel: Carousel;
  @Input() attendanceStats: AttendanceStat[] = [];
  @Output() pageChanged = new EventEmitter<AttendanceStat>();

  defaultPage = 0;
  isHome = true;
  attendanceType = AttendanceTypeEnum;

  private readonly router = inject(Router);
  private readonly gtmService = inject(PathwaysGoogleTagManagerService);

  ngOnInit(): void {
    this.isHome = this.router.url === `/${PathwaysAccountRoutes.HOME}`;
  }

  ngOnChanges({ attendanceStats }: SimpleChanges): void {
    if (attendanceStats?.currentValue) {
      this.defaultPage = this.attendanceStats.findIndex((item) => item.frequency === AttendanceTypeEnum.weekly);
    }
  }

  ngAfterViewInit(): void {
    const prevButton = this.carousel?.el?.nativeElement?.querySelector('.p-carousel-prev');
    const nextButton = this.carousel?.el?.nativeElement?.querySelector('.p-carousel-next');

    if (prevButton) {
      prevButton.addEventListener('click', this.onPrevClick.bind(this));
    }

    if (nextButton) {
      nextButton.addEventListener('click', this.onNextClick.bind(this));
    }
  }

  onPageChange({ page }: onPageChangeEvent) {
    this.pageChanged.emit(this.attendanceStats.at(page));
  }

  onPrevClick() {
    this.gtmService.pushTag({
      event: this.isHome ? GtmEventNames.HOME_ATTENDANCE_CAROUSEL_LEFT_CHEVRON : GtmEventNames.DETAIL_ATTENDANCE_CAROUSEL_LEFT_CHEVRON,
    });
  }

  onNextClick() {
    this.gtmService.pushTag({
      event: this.isHome ? GtmEventNames.HOME_ATTENDANCE_CAROUSEL_RIGHT_CHEVRON : GtmEventNames.DETAIL_ATTENDANCE_CAROUSEL_RIGHT_CHEVRON,
    });
  }
}
