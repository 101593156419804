<p-panel [styleClass]="styleClass" [showHeader]="showHeader">
  <ng-template pTemplate="header">
    <div class="notification-toast__header" [ngClass]="'notification-toast__header--' + severity">
      <img *ngIf="headerIcon" alt="Header icon" [ngSrc]="headerIcon" width="20" height="20" />
      <span i18n>{{ header }}</span>
    </div>
  </ng-template>
  <ng-container i18n>
    <span class="notification-toast__content">
      <ng-content></ng-content>
    </span>
  </ng-container>

  <ng-container *ngIf="secondaryLabel || primaryLabel">
    <ng-template pTemplate="footer">
      <pw-button
        *ngIf="secondaryLabel"
        [label]="secondaryLabel"
        [severity]="severity"
        [secondary]="true"
        [gtmId]="secondaryGtmId"
        (buttonClicked)="secondaryClicked.emit()"
      ></pw-button>
      <pw-button *ngIf="primaryLabel" [label]="primaryLabel" [severity]="severity" [gtmId]="primaryGtmId" (buttonClicked)="primaryClicked.emit()"></pw-button>
    </ng-template>
  </ng-container>
</p-panel>
