import { AfterContentInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { PathwaysGoogleTagManagerService } from '@pw/shared/google-tag-manager';
import { fromEvent, merge, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

interface GoogleTagManagerTrackEvent {
  googleTagManagerId: string;
  eventName: string;
  event: Event;
}

/**
 * How to use it:
 * 1. Add the directive `pwGoogleTagManagerTrackEvents` to a parent element where you want to track events to Google Tag Manager (GTM)
 * 2. Add the attribute `data-gtm-id` to the elements you want to track with the name of the tag you want to send. i.e. data-gtm-id="my-button-clicked"
 * 3. (Optional) If you want to track an event different to 'click' or several of them, add also a `data-gtm-events` property with a list of events comma separated.
 *    i.e. data-gtm-events="keydown, blur"
 * Note: The event that fired the tag is sent as `type` to GTM
 */
@Directive({
  selector: '[pwGoogleTagManagerTrackEvents]',
})
export class GoogleTagManagerTrackEventsDirective implements OnDestroy, AfterContentInit {
  private subscriptions = new Subscription();
  private observer = new MutationObserver(() => {
    this.subscriptions.unsubscribe();
    this.subscriptions = new Subscription();
    this.initTags();
  });

  constructor(private elementRef: ElementRef, private gtmService: PathwaysGoogleTagManagerService) {}

  ngAfterContentInit(): void {
    this.setObserver();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.observer.disconnect();
  }

  private initTags() {
    const elementsToTrack: NodeListOf<HTMLElement> = this.elementRef.nativeElement.querySelectorAll('[data-gtm-id]');
    const observableList: Observable<GoogleTagManagerTrackEvent>[] = Array.from(elementsToTrack)
      .filter((targetElement: HTMLElement) => !!targetElement.dataset.gtmId)
      .flatMap((targetElement: HTMLElement) => {
        // If the element has not a data-gtm-events tag take 'click' as default
        const eventsToTrack: string[] = targetElement.dataset.gtmEvents?.split(',') ?? ['click'];
        const gtmId = targetElement.dataset.gtmId;
        return eventsToTrack.map((targetEventName: string) =>
          fromEvent(targetElement, targetEventName).pipe(
            map((event: Event) => ({
              googleTagManagerId: gtmId,
              eventName: targetEventName,
              event,
            })),
          ),
        );
      });

    this.subscriptions.add(
      merge(...observableList).subscribe(({ googleTagManagerId, eventName }: GoogleTagManagerTrackEvent) =>
        this.gtmService.pushTag({ event: googleTagManagerId, type: eventName }),
      ),
    );
  }

  private setObserver() {
    this.observer.observe(this.elementRef.nativeElement, { attributes: true, childList: true, subtree: true });
  }
}
