<div class="notification-panel">
  <div class="notification-panel__header">
    <span class="notification-panel__header-title">Notifications</span>
    <img
      class="notification-panel__header-close"
      alt="Close icon"
      [ngSrc]="closeIcon"
      width="24"
      height="24"
      data-gtm-id="notification-panel-close"
      (click)="closePanel()"
    />
  </div>
  <div *ngIf="!(studentNotifications?.length > 0); else showPanel" class="notification-panel__not-available">
    <img alt="Girl celebrating" [ngSrc]="celebrationImg" width="274" height="274" />
    <span class="notification-panel__celebration">You're all caught up</span>
  </div>
  <ng-template #showPanel>
    <div class="notification-panel__list">
      <ng-container *ngFor="let notification of studentNotifications" [ngSwitch]="notification.name">
        <ng-container *ngSwitchCase="notificationNameEnum.enrolment_documents">
          <pw-notification-toast [severity]="toastSeverity.Primary" header="REMINDER - ENROLLMENT">
            Thank you for registering with us! Please bring all the
            <a
              href="https://downloads.kaplanpathways.com/downloads/documents-checklist"
              target="_blank"
              data-gtm-id="prearrival-reminder-enrolment-document-link"
            >
              relevant documents
            </a>
            with you when you enrol.
          </pw-notification-toast>
        </ng-container>

        <ng-container *ngSwitchCase="notificationNameEnum.proof_of_accommodation">
          <pw-notification-toast [severity]="toastSeverity.Primary" header="REMINDER - ACCOMMODATION">
            <span class="semibold">Proof of accommodation</span>
            - Please bring a reference letter with you and either the latest bill or rental contract belonging to the person you are staying with. Here is an
            example of a
            <a href="https://static.kaplanpathways.com/Reference+letter+template.docx" target="_blank" data-gtm-id="prearrival-reminder-reference-letter">
              reference letter.
            </a>
          </pw-notification-toast>
        </ng-container>

        <ng-container *ngSwitchCase="notificationNameEnum.tuition_fee">
          <pw-notification-toast
            *ngIf="notification.status !== taskItemStatusEnum.completed"
            [severity]="toastSeverity.Warning"
            header="ATTENTION - TUITION FEE"
            secondaryLabel="Dismiss"
            secondaryGtmId="payment-reminder-dismiss"
            primaryLabel="Pay Tuition Fee"
            primaryGtmId="payment-reminder-pay-tuition"
            (secondaryClicked)="dismissTuitionNotification(notification.id)"
            (primaryClicked)="openOppUrl()"
          >
            If you have already paid, you do not need to do anything. Please pay your tuition fee, if you have not paid it already. You can then access your
            timetable and course materials, and start attending classes.
          </pw-notification-toast>
        </ng-container>

        <ng-container *ngSwitchCase="notificationNameEnum.pre_arrival_tasks">
          <hr />
          <pw-pre-arrival-tasks-notification [preArrivalTasksNotification]="notification"></pw-pre-arrival-tasks-notification>
          <hr />
        </ng-container>
      </ng-container>
      <pw-notification-info-section *ngIf="infoNotifications" [infoNotifications]="infoNotifications"></pw-notification-info-section>
    </div>
  </ng-template>
</div>
