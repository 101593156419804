import { DOCUMENT } from '@angular/common';
import { inject, Inject, Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult } from '@azure/msal-browser';
import { environment, getAccountPortalUrl, PathwaysAccountRoutes } from '@pw/shared/environment';
import { PW_NOTIFICATION_PANEL_OPEN, SignOutType } from '@pw/shared/types';
import { from, Observable } from 'rxjs';
import { silentRequest } from '../config/auth-config';
import { PathwaysBroadcastSignOutService } from './broadcast-sign-out.service';
import { LocalStorageService } from '@klg/shared/storage';

@Injectable({
  providedIn: 'root',
})
export class PathwaysAuthService {
  authenticationResult: AuthenticationResult | null = null;
  masterProfileId = '';
  private idToken: string;

  private readonly localStorageService = inject(LocalStorageService);

  constructor(
    private readonly authService: MsalService,
    private readonly broadcastSignOutService: PathwaysBroadcastSignOutService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  login(): void {
    // Using the client ID as the scope indicates that your app needs an access token that can be used against your own service or web API, represented by the same client ID
    this.authService.loginRedirect({ scopes: [environment.B2C_CLIENT_ID], redirectStartPage: getAccountPortalUrl() });
  }

  logout(redirectPathName?: SignOutType | string): Observable<void> {
    let postLogoutRedirectUri = this.document.location.href;
    let signOutType: SignOutType = SignOutType.DEFAULT;
    if (redirectPathName === SignOutType.EXPIRED || redirectPathName === SignOutType.DEFAULT) {
      signOutType = redirectPathName;
      postLogoutRedirectUri = getAccountPortalUrl(`${PathwaysAccountRoutes.SIGN_OUT}/${signOutType}`);
    } else if (redirectPathName) {
      postLogoutRedirectUri = getAccountPortalUrl(redirectPathName);
    }

    this.localStorageService.removeItem(PW_NOTIFICATION_PANEL_OPEN);

    return this.authService.logoutRedirect({
      postLogoutRedirectUri,
      onRedirectNavigate: () => {
        this.broadcastSignOut(signOutType);
        // Return true to continue navigation after local logout, and logout in Azure
        return true;
      },
    });
  }

  logoutLocal(): Observable<void> {
    return this.authService.logoutRedirect({
      onRedirectNavigate: () => {
        this.broadcastSignOut();
        // Return false to stop navigation after local logout
        return false;
      },
    });
  }

  ssoSilent(): Observable<void> {
    return from(
      this.authService.instance
        .acquireTokenSilent({
          ...silentRequest,
          scopes: [...silentRequest.scopes, environment.B2C_CLIENT_ID],
          account: this.getActiveAccount(),
          forceRefresh: true,
        })
        .then((result: AuthenticationResult) => {
          this.authenticationResult = result;
        })
        .catch((error) => {
          console.error('Error occurred while acquiring token', error);
          this.authenticationResult = null;
        }),
    );
  }

  getActiveAccount(): AccountInfo {
    const activeAccount = this.authService.instance.getActiveAccount();
    return activeAccount || (this.getAllAccounts().length ? this.getAllAccounts()[0] : null);
  }

  getAllAccounts(): AccountInfo[] {
    return this.authService.instance.getAllAccounts() ?? [];
  }

  getActiveAccountMasterProfileId(): string {
    const accountInfo: AccountInfo = this.getActiveAccount();
    return (accountInfo?.idTokenClaims['MasterProfileId'] || this.masterProfileId) as string;
  }

  setMasterProfileId(masterProfileId: string): void {
    this.masterProfileId = masterProfileId;
  }

  getActiveAccountEmail(): string {
    const accountInfo: AccountInfo = this.getActiveAccount();
    return accountInfo?.idTokenClaims['email'] as string;
  }

  getAccessToken(): string {
    return this.authenticationResult?.accessToken;
  }

  setIdToken(token: string): void {
    this.idToken = token;
  }

  getIdToken(): string {
    return this.idToken;
  }

  getBearerToken(): string {
    return `Bearer ${this.getAccessToken() || this.getIdToken()}`;
  }

  private broadcastSignOut(redirectPathName: string = '') {
    this.broadcastSignOutService.publishSignOut(redirectPathName.includes(SignOutType.EXPIRED) ? SignOutType.EXPIRED : SignOutType.DEFAULT);
  }
}
