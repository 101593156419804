import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserverService } from '@klg/shared/utils-dom';
import { AttendanceStat, colleges, StudentAttendanceService } from '@pw/profile-data-access';
import { PathwaysAccountRoutes } from '@pw/shared/environment';
import { GtmEventNames } from '@pw/shared/types';
import { subDays } from 'date-fns';
import { combineLatest, Observable, Subject, takeUntil, tap } from 'rxjs';
import { NotificationPanelService } from '../../../services';
import { PwButtonComponent } from '../../atoms';
import { AttendanceCardComponent, CardShellComponent } from '../../molecules';

@Component({
  selector: 'pw-attendance-overview',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, CardShellComponent, AttendanceCardComponent, PwButtonComponent],
  templateUrl: './attendance-overview.component.html',
  styleUrls: ['./attendance-overview.component.scss'],
})
export class AttendanceOverviewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() college = '';

  yesterday = subDays(new Date(), 1);
  shortCard = false;
  chevronRightImg = '/assets/icons/chevron-right.svg';
  gtmEventNames = GtmEventNames;
  absenceFormUrl = '';

  attendanceStats$: Observable<AttendanceStat[]>;
  isTablet$: Observable<boolean>;
  isPanelOpen$: Observable<boolean>;
  isAttendanceDataAvailable = false;

  private readonly destroy$ = new Subject<void>();

  private readonly router = inject(Router);
  private readonly studentAttendanceService = inject(StudentAttendanceService);
  private readonly breakpointObserverService = inject(BreakpointObserverService);
  private readonly notificationPanelService = inject(NotificationPanelService);

  constructor() {
    this.attendanceStats$ = this.studentAttendanceService.attendanceStats$;
    this.isTablet$ = this.breakpointObserverService.isTablet$;
    this.isPanelOpen$ = this.notificationPanelService.isPanelOpen$;
  }

  ngOnInit(): void {
    combineLatest([this.isTablet$, this.isPanelOpen$])
      .pipe(
        tap(([isTablet, isPanelOpen]) => (this.shortCard = isTablet && isPanelOpen)),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.attendanceStats$
      .pipe(
        tap((attendanceStats) => {
          this.isAttendanceDataAvailable = attendanceStats !== undefined;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnChanges({ college }: SimpleChanges): void {
    if (college?.currentValue) {
      this.absenceFormUrl = colleges.find((college) => college.code === this.college)?.absenceFormUrl;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  goToAttendanceOverview() {
    this.router.navigate(['/', PathwaysAccountRoutes.ATTENDANCE_OVERVIEW]);
  }
}
