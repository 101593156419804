import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostBinding, inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { BreakpointObserverService } from '@klg/shared/utils-dom';
import { AttendanceStat, AttendanceTypeEnum, StudentAttendanceService } from '@pw/profile-data-access';
import { PathwaysGoogleTagManagerService } from '@pw/shared/google-tag-manager';
import { GtmEventNames } from '@pw/shared/types';
import { TabViewModule } from 'primeng/tabview';
import { combineLatest, Observable, Subject, takeUntil, tap } from 'rxjs';
import { NotificationPanelService } from '../../../services';
import { AttendancePercentageComponent, AttendanceTableComponent } from '../../atoms';
import { AttendanceCarouselComponent } from '../attendance-carousel/attendance-carousel.component';
import { CardShellComponent } from '../card-shell/card-shell.component';

// based on PrimeNG code (https://github.com/primefaces/primeng/blob/15.2.0/src/app/components/tabview/tabview.ts#L329)
type onTabChangeEvent = { originalEvent: Event; index: number };

@Component({
  selector: 'pw-attendance-tabs',
  standalone: true,
  imports: [CommonModule, TabViewModule, AttendancePercentageComponent, AttendanceTableComponent, AttendanceCarouselComponent, CardShellComponent],
  templateUrl: './attendance-tabs.component.html',
  styleUrls: ['./attendance-tabs.component.scss'],
})
export class AttendanceTabsComponent implements OnInit, OnChanges, OnDestroy {
  @HostBinding('class.padded') showCarousel = false;
  @Input() attendanceStats: AttendanceStat[] = [];
  @Output() tabChanged = new EventEmitter<AttendanceStat>();

  attendanceType = AttendanceTypeEnum;
  showCardShell = false;
  gtmEventNames: GtmEventNames[] = [];
  isMobile$: Observable<boolean>;
  isTablet$: Observable<boolean>;
  isPanelOpen$: Observable<boolean>;
  activeTabIndex$: Observable<number>;

  private readonly breakpointObserverService = inject(BreakpointObserverService);
  private readonly notificationPanelService = inject(NotificationPanelService);
  private readonly attendanceService = inject(StudentAttendanceService);
  private readonly gtmService = inject(PathwaysGoogleTagManagerService);
  private readonly destroy$ = new Subject<void>();

  constructor() {
    this.isMobile$ = this.breakpointObserverService.isMobile$;
    this.isTablet$ = this.breakpointObserverService.isTablet$;
    this.isPanelOpen$ = this.notificationPanelService.isPanelOpen$;
    this.activeTabIndex$ = this.attendanceService.activeTabIndex$;
  }

  ngOnInit(): void {
    combineLatest([this.isMobile$, this.isTablet$, this.isPanelOpen$])
      .pipe(
        tap(([isMobile, isTablet, isPanelOpen]) => {
          this.showCarousel = isMobile || (isTablet && isPanelOpen);
          this.showCardShell = !this.showCarousel && isTablet;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnChanges({ attendanceStats }: SimpleChanges): void {
    if (attendanceStats?.currentValue?.length) {
      this.gtmEventNames = this.attendanceStats.map((stat) => {
        switch (stat.frequency) {
          case AttendanceTypeEnum.weekly:
            return GtmEventNames.DETAIL_ATTENDANCE_WEEKLY_TAB;
          case AttendanceTypeEnum.monthly:
            return GtmEventNames.DETAIL_ATTENDANCE_MONTHLY_TAB;
          case AttendanceTypeEnum.overall:
            return GtmEventNames.DETAIL_ATTENDANCE_OVERALL_TAB;
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onTabChange({ index }: onTabChangeEvent) {
    this.tabChanged.emit(this.attendanceStats[index]);
    this.attendanceService.setTabIndex(index);
    this.gtmService.pushTag({ event: this.gtmEventNames[index] });
  }
}
