<h2 class="attendance-overview__heading">
  <a [attr.data-gtm-id]="gtmEventNames.HOME_ATTENDANCE_LINK" (click)="goToAttendanceOverview()" i18n>
    Your attendance
    <img alt="Chevron right icon" [ngSrc]="chevronRightImg" width="16" height="16" />
  </a>
</h2>
<div *ngIf="(attendancesError$ | async) !== undefined; else attendanceOverview" class="attendance-overview__no-data" i18n>
  We can't load your attendance. Try <a (click)="refresh()">refreshing</a> the page. If your attendance still isn't loading, please
  <a [href]="'mailto:' + supportEmail">contact us.</a>
</div>
<span *ngIf="isAttendanceDataAvailable && (attendancesError$ | async) === undefined" class="attendance-overview__updated" i18n
  >Last updated on {{ yesterday | date : 'd MMM YYYY' }}</span
>
<pw-button
  buttonId="attendance-overview-submit-absence-form-button"
  class="attendance-overview__button"
  label="Submit Absence Form"
  [secondary]="true"
  icon="arrow-up-right"
  [gtmId]="gtmEventNames.HOME_ATTENDANCE_SUBMIT_ABSENCE_FORM_BUTTON"
  [href]="absenceFormUrl"
></pw-button>
<ng-template #attendanceOverview>
  <pw-attendance-card
    *ngIf="isAttendanceDataAvailable"
    class="attendance-overview__statistics"
    [class.attendance-overview__statistics--short-card]="shortCard"
    [attendanceStats]="attendanceStats$ | async"
  ></pw-attendance-card>
  <div *ngIf="!isAttendanceDataAvailable" class="attendance-overview__no-data" i18n>No data available yet</div>
</ng-template>
