import { Component } from '@angular/core';

@Component({
  selector: 'pw-reporting-forbidden-page',
  templateUrl: './forbidden-page.component.html',
  styleUrls: ['./forbidden-page.component.scss'],
})
export class ReportingForbiddenPageComponent {
  public requestPermissionsEmail = 'it@kaplaninternational.com';
}
