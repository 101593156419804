import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BreakpointObserverService } from '@klg/shared/utils-dom';
import {
  Absence,
  AttendanceStat,
  AttendanceSummary,
  AttendanceTypeEnum,
  colleges,
  createDateFromString,
  StudentAttendanceService,
} from '@pw/profile-data-access';
import { GtmEventNames } from '@pw/shared/types';
import { subDays } from 'date-fns';
import { combineLatest, distinctUntilChanged, filter, merge, Observable, Subject, take, takeUntil, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationPanelService } from '../../../services';
import { AttendanceTableComponent, PwButtonComponent } from '../../atoms';
import { AttendanceCardComponent, AttendanceTabsComponent } from '../../molecules';

@Component({
  selector: 'pw-attendance-absences',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, AttendanceCardComponent, PwButtonComponent, AttendanceTabsComponent, AttendanceTableComponent],
  templateUrl: './attendance-absences.component.html',
  styleUrls: ['./attendance-absences.component.scss'],
})
export class AttendanceAbsencesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() college = '';

  yesterday = subDays(new Date(), 1);
  currentTab: string = AttendanceTypeEnum.monthly;
  attendanceStats: AttendanceStat[] = [];
  absences: Absence[] = [];
  shortCard = false;
  infoImg = '/assets/icons/info.svg';
  gtmEventNames = GtmEventNames;
  absenceFormUrl = '';
  serviceEmail = '';

  filters$!: Observable<Date[]>;
  isTablet$: Observable<boolean>;
  isPanelOpen$: Observable<boolean>;
  isAttendanceDataAvailable = false;

  private destroy$ = new Subject<void>();

  private readonly studentAttendanceService = inject(StudentAttendanceService);
  private readonly breakpointObserverService = inject(BreakpointObserverService);
  private readonly notificationPanelService = inject(NotificationPanelService);

  constructor() {
    this.isTablet$ = this.breakpointObserverService.isTablet$;
    this.isPanelOpen$ = this.notificationPanelService.isPanelOpen$;
  }

  ngOnInit(): void {
    this.studentAttendanceService.attendanceStats$
      ?.pipe(
        tap((attendanceStats) => {
          this.isAttendanceDataAvailable = attendanceStats !== undefined;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((value) => {
        this.attendanceStats = value;
      });

    merge(this.studentAttendanceService.currentMonthlyFilters$, this.studentAttendanceService.currentWeeklyFilters$)
      .pipe(
        distinctUntilChanged(),
        filter(() => this.currentTab !== AttendanceTypeEnum.overall),
        map(() =>
          this.currentTab === AttendanceTypeEnum.monthly
            ? this.studentAttendanceService.currentMonthlyFilters$
            : this.studentAttendanceService.currentWeeklyFilters$,
        ),
        tap((newFilters$) => this.filterRows(newFilters$)),
      )
      .subscribe();

    this.breakpointObserverService.isMobile$
      .pipe(
        map((isMobile) => {
          if (isMobile) {
            this.currentTab = AttendanceTypeEnum.weekly;
            return this.studentAttendanceService.currentWeeklyFilters$;
          }
          return this.studentAttendanceService.currentMonthlyFilters$;
        }),
        tap((newFilters$) => this.filterRows(newFilters$)),
        takeUntil(this.destroy$),
      )
      .subscribe();

    combineLatest([this.isTablet$, this.isPanelOpen$])
      .pipe(
        tap(([isTablet, isPanelOpen]) => (this.shortCard = isTablet && isPanelOpen)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnChanges({ college }: SimpleChanges): void {
    if (college?.currentValue) {
      const { absenceFormUrl, serviceEmail } = colleges.find((college) => college.code === this.college);
      this.absenceFormUrl = absenceFormUrl;
      this.serviceEmail = serviceEmail;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onTabChanged(statistic: AttendanceStat) {
    this.currentTab = statistic.frequency;
    let newFilters$: Observable<Date[]>;
    if (statistic.frequency === AttendanceTypeEnum.weekly) {
      newFilters$ = this.studentAttendanceService.currentWeeklyFilters$;
    } else if (statistic.frequency === AttendanceTypeEnum.monthly) {
      newFilters$ = this.studentAttendanceService.currentMonthlyFilters$;
    }

    this.filterRows(newFilters$);
  }

  private filterRows(newFilters$: Observable<Date[]>) {
    this.filters$ = newFilters$;

    if (this.currentTab === AttendanceTypeEnum.overall) {
      this.studentAttendanceService.attendanceResult$.pipe(take(1)).subscribe((value) => (this.absences = value.overall.absences));
      return;
    }

    combineLatest([this.filters$, this.studentAttendanceService.attendanceResult$])
      .pipe(
        take(1),
        tap(([filters, attendanceResult]) => {
          const currentSummary: AttendanceSummary = attendanceResult[this.currentTab].find((attendanceSummary: AttendanceSummary) => {
            const startDate = createDateFromString(attendanceSummary.startDate);
            const endDate = createDateFromString(attendanceSummary.endDate);
            return startDate >= filters[0] && endDate <= filters[1];
          });

          this.absences = currentSummary?.absences || [];
        }),
      )
      .subscribe();
  }
}
