import { inject, Injectable } from '@angular/core';
import { environment } from '@pw/shared/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { PW_NOTIFICATION_PANEL_OPEN } from '@pw/shared/types';
import { LocalStorageService } from '@klg/shared/storage';

@Injectable({
  providedIn: 'root',
})
export class NotificationPanelService {
  isPanelOpen$: Observable<boolean>;
  isConfirmUkAddressOpen$: Observable<boolean>;

  private readonly isPanelOpen = new BehaviorSubject<boolean>(false);
  private readonly isConfirmUkAddressOpen = new BehaviorSubject<boolean>(false);

  private readonly localStorageService = inject(LocalStorageService);

  constructor() {
    this.isPanelOpen$ = this.isPanelOpen.asObservable();
    this.isConfirmUkAddressOpen$ = this.isConfirmUkAddressOpen.asObservable();
  }

  togglePanel(isOpen: boolean) {
    const isPanelOpen = environment.PRE_ARRIVAL_CHECKLIST_ENABLED ? isOpen : false;
    this.isPanelOpen.next(isPanelOpen);
    this.localStorageService.setItem(PW_NOTIFICATION_PANEL_OPEN, isPanelOpen);
  }

  toggleConfirmUkAddress(isOpen: boolean) {
    this.isConfirmUkAddressOpen.next(isOpen);
  }
}
