import { Injectable } from '@angular/core';
import { environment } from '@pw/shared/environment';
import { SignOutType } from '@pw/shared/types';
import { BroadcastChannel as BroadcastChannelPolyfill } from 'broadcast-channel';
import { fromEvent, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PathwaysBroadcastSignOutService {
  private broadcastChannel: BroadcastChannel | BroadcastChannelPolyfill;
  public onSignOut$: Observable<SignOutType>;

  constructor() {
    try {
      this.broadcastChannel = new BroadcastChannel(environment.BROADCAST_CHANNEL.SIGN_OUT);
    } catch (error) {
      this.broadcastChannel = new BroadcastChannelPolyfill(environment.BROADCAST_CHANNEL.SIGN_OUT);
    }
    this.onSignOut$ = fromEvent(this.broadcastChannel, 'message').pipe(map(({ data }: MessageEvent) => data as SignOutType));
  }

  publishSignOut(type: SignOutType = SignOutType.DEFAULT): void {
    this.broadcastChannel?.postMessage(type);
  }
}
