import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { LogoutGuard, UserResolver } from '@pw/reporting/auth';
import { ReportingRoutes } from '@pw/shared/environment';
import { LogoutComponent } from './auth/logout/logout.component';
import { BasePageComponent } from './components/base-page/base-page.component';
import { ReportingForbiddenPageComponent } from './components/forbidden-page/forbidden-page.component';
import { ReportingNotFoundPageComponent } from './components/not-found-page/not-found-page.component';

export const reportingRoutes: Routes = [
  {
    path: '',
    redirectTo: ReportingRoutes.INCIDENTS,
    pathMatch: 'full',
  },
  {
    path: ReportingRoutes.LOGOUT,
    component: LogoutComponent,
    canActivate: [LogoutGuard],
  },
  {
    path: ReportingRoutes.INCIDENTS,
    component: BasePageComponent,
    loadChildren: () => import('@pw/reporting/incidents').then((m) => m.PathwaysReportingIncidentsModule),
    resolve: {
      currentUser: UserResolver,
    },
    canActivate: [MsalGuard],
    canActivateChild: [MsalGuard],
  },
  {
    path: ReportingRoutes.FORBIDDEN,
    component: ReportingForbiddenPageComponent,
  },
  {
    path: '**',
    component: ReportingNotFoundPageComponent,
  },
];
