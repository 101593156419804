<ng-container *ngIf="isFirstStep; else secondStep">
  <p class="confirm-uk-address__subheader">Have you already booked or are you going to book an accommodation?</p>
  <pw-radio-buttons
    radioButtonName="accommodation"
    [options]="firstStepOptions"
    [optionSelected]="firstOptionSelected"
    (selectedOption)="firstOptionSelected = $event"
  ></pw-radio-buttons>
</ng-container>
<ng-template #secondStep>
  <div *ngIf="(isMobile$ | async) === false" class="confirm-uk-address__back-link">
    <img alt="Arrow left icon" [ngSrc]="arrowLeftImg" width="16" height="16" />
    <a href="javascript:void(0)" (click)="backToFirstStep()">{{ firstOptionSelected.name }}</a>
  </div>
  <p class="confirm-uk-address__second-option-subheader">Is your name on the rental contract?</p>
  <p class="confirm-uk-address__second-option-desc">When you confirm either yes or no, you will be redirected to a new page to give us more details.</p>
  <pw-radio-buttons
    radioButtonName="accommodation"
    [options]="secondStepOptions"
    [optionSelected]="secondOptionSelected"
    (selectedOption)="secondOptionSelected = $event"
  ></pw-radio-buttons>
</ng-template>
<div class="confirm-uk-address__buttons">
  <pw-button
    class="confirm-uk-address__confirm"
    label="Confirm"
    [disabled]="!firstOptionSelected || (!isFirstStep && !secondOptionSelected)"
    [gtmId]="isFirstStep ? 'confirm-uk-address-part1-confirm' : 'confirm-uk-address-part2-confirm'"
    (buttonClicked)="handleConfirm()"
  ></pw-button>
  <pw-button
    class="confirm-uk-address__cancel"
    label="Cancel"
    [secondary]="true"
    [gtmId]="isFirstStep ? 'confirm-uk-address-part1-cancel' : 'confirm-uk-address-part2-cancel'"
    (buttonClicked)="handleCancel()"
  ></pw-button>
</div>
