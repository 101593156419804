import { Injectable, NgZone } from '@angular/core';
import { ReportingAuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AutoLogoutService {
  constructor(private authService: ReportingAuthService, private ngZone: NgZone) {}

  addListener() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.checkTokenExpiry());
    });
  }

  checkTokenExpiry() {
    const activeAccount = this.authService.getActiveAccount();
    if (!activeAccount?.idTokenClaims?.exp) {
      this.authService.azureLogout();
    }

    const expiryInMilliseconds = Number(activeAccount?.idTokenClaims?.exp) * 1000;
    const now = new Date().getTime();
    const tokenExpired = expiryInMilliseconds < now;

    this.ngZone.run(() => {
      if (tokenExpired) {
        this.authService.azureLogout();
      }
    });
  }
}
