import { NotificationItemApiDto } from '@pw/api/notification';

export type StudentNotification = NotificationItemApiDto;
export type StudentNotificationName = NotificationItemApiDto.NameApiDtoEnum;
export const StudentNotificationNameEnum = NotificationItemApiDto.NameApiDtoEnum;
export type StudentNotificationStatus = NotificationItemApiDto.StatusApiDtoEnum;
export const StudentNotificationStatusEnum = NotificationItemApiDto.StatusApiDtoEnum;
export type StudentNotificationCategory = NotificationItemApiDto.CategoryApiDtoEnum;
export const StudentNotificationCategoryEnum = NotificationItemApiDto.CategoryApiDtoEnum;
export type StudentNotificationType = NotificationItemApiDto.TypeApiDtoEnum;
export const StudentNotificationEnum = NotificationItemApiDto.TypeApiDtoEnum;
